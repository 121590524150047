export const fil = {
  _LOGIN: {
    _EMAIL: "Email",
    _PASSWORD: "Password",
    _REMEMBER: "Tandaan ako",
    _LOGIN: "MAG-LOG IN",
    _SIGNUP: "Mag-sign up",
    _FORGOTPASSWORD: "Nakalimutan ang iyong password?",
    _NOACCOUNT: "Wala ka pang account?",
    _ERROR_EMAIL: "Mukhang hindi ka pa rehistrado! Subukang muli.",
    _ERROR_PASSWORD: "Maling password",
    _SUCCESS: "Matagumpay na pag-login",
  },
  _REGISTER: {
    _REGISTER: "Magrehistro ng Account",
    _NAME: "Username",
    _EMAIL: "Iyong email",
    _PASSWORD: "Iyong password",
    _CONFIRMPASSWORD: "Kumpirmahin ang Password",
    _WHATSAPP: "WhatsApp",
    _TELEGRAM: "Telegram (opsyonal)",
    _HAVEACCOUNT: "May account na ako",
    _ERROR_REFERAL: "Maling referral code",
    _ERROR_EMAIL: "Ang email na ito ay umiiral na",
    _SUCCESS: "Matagumpay na Rehistrasyon",
  },
  _SIDEBAR: {
    _PAGES: "Mga Pahina",
    _OPEN: "Buksan ang sidebar",
    _DASHBOARD: "Home",
    _NETWORK: "Network",
    _FINANCE: "Wallet",
    _PACKAGES: "Mga Plano",
    _SIGNOUT: "Mag-logout",
    _LANGUAGE: "Wika",
    _PROFILE: "Profile",
  },
  _DASHBOARD: {
    _TITLE: "Balanse",
    _TITLE2: "Deposito",
    _TITLE3: "Kabuuang Kita",
    _TITLE4: "Unilevel na Kita",
    _TITLE5: "Bonus sa Ranggo",
    _TITLE6: "Plano",
    _TOTAL: "Kabuuan",
    _TABLE3: "Listahan ng Mga Package",
    _START: "Simula",
    _FINISH: "Tapos",
    _STATUS: "Katayuan",
    _STATUS_ACTIVE: "Aktibo",
    _STATUS_INACTIVE: "Hindi Aktibo",
    _CURRENT: "Kasalukuyan",
    _UPGRADE: "I-upgrade",
    _COPYMESSAGE: "Matagumpay na nakopya",
    _NOTIFICATION1: "Bagong Deposito",
    _NOTIFICATION2: "Kita mula sa Cryptotradex",
    _NOTIFICATION3: "Kita mula sa Unilevel",
    _NOTIFICATION4: "Walang Hanggang Ranggo",
    _NOTIFICATION5: "Bumili ng Package",
    _NOTIFICATION7: "Umulit na Direktang Referral na Bonus",
    _PREV: "Nakaraan",
    _NEXT: "Susunod",
    _TABLE4_A: "Operasyon",
    _TABLE4_B: "Kita",
    _TABLE4_C: "ROI",
    _TABLE4_D: "Petsa",
    _SHARED: "Ibahagi ang iyong referral link",
  },
  _FINANCE: {
    _DEPOSIT: "Deposito",
    _QR: "I-scan ang sumusunod na QR o kopyahin ang address",
    _NETWORK: "Network",
    _INSTRUCTIONS: "Mga Tagubilin para sa Pagpadala ng TRON (TRX)",
    _STEP1: "Tiyaking ang napiling network ay TRON (TRC20).",
    _STEP2: "Ipadala ang halagang TRON (TRX) sa sumusunod na wallet address.",
    _COPY: "Kopyahin",
    _DISCLAIMER:
      "Paunawa: Hindi kami mananagot para sa mga transaksyon na ginawa sa labas ng TRON (TRC20) network o sa pagpapadala ng iba pang cryptocurrency bukod sa TRON (TRX). Anumang pagkawala ng pondo dahil sa mga pagkakamaling ito ay magiging responsibilidad ng nagpapadala.",
    _TABLE1: "Kasaysayan ng Deposito",
    _TABLE1_A: "Order",
    _TABLE1_B: "Halaga",
    _TABLE1_C: "Petsa",
    _TABLE1_D: "Katayuan",
    _SUCCESS: "Matagumpay na naidagdag ang package",
    _COPYMESSAGE: "Matagumpay na nakopya ang address ng wallet",
  },
  _PACKAGES: {
    _ATTENTION:
      "Pansin: Mayroon kang corporate account. Sa pagbili ng package, ire-reset mo ang account at lahat ng benepisyo nito.",
    _PACKAGES: "Piliin ang pinakamahusay na plano para sa iyo",
    _SUBTITLE: "Mayroon kang 8 pagpipilian",
    _BALANCE: "Balanse ng Deposito",
    _PACKAGE: "Package",
    _PACKAGE_1: "Makakakuha ka mula sa level",
    _PACKAGE_2: "Kumita ng hanggang 3X",
    _PACKAGE_3: "Araw-araw na ROI",
    _BUTTON1: "Puno na",
    _BUTTON2: "Bumili",
    _CALCULATE: "Kalkulahin ang Plano",
    _RECHARGE: "Wala kang sapat na pondo, mag-recharge ng iyong wallet",
    _PACKAGEMESSAGE: "Kailangan mong bumili ng mas mataas na package",
    _SUCCESS: "Matagumpay na naidagdag ang package",
    _UPDATE: "Presyo ng pag-upgrade ng package",
  },
  _NETWORK: {
    _TODAY: "Ngayon",
    _AVAILABLE: "Magagamit",
    _WITHDRAW: "Kabuuang Nai-withdraw",
    _SEARCHLABEL: "Paghahanap ng Email",
    _SEARCH: "Maghanap",
    _NETWORK: "Network",
    _DIRECTS: "Mga Direktang Referral",
    _SUCCESS: "Matagumpay na paghahanap",
    _ERROR: "Hindi bahagi ng iyong binary tree.",
    _LEVEL: "Level",
    _USERS: "Mga User",
    _AMOUNT: "Halaga",
    _USERNAME: "Username",
    _EMAIL: "Email",
    _WHATSAPP: "WhatsApp",
    _PACKAGE: "Package",
  },
  _PROFILE: {
    _TITLE: "Profile",
    _NAME: "Iyong pangalan",
    _EMAIL: "Iyong email",
    _WHATSAPP: "WhatsApp",
    _CURRENTPASSWORD: "Kasalukuyang password",
    _PASSWORD: "Bagong password",
    _CONFIRMPASSWORD: "Kumpirmahin ang bagong password",
    _WALLET: "Address ng Withdrawal",
    _ACTION: "I-edit",
    _TIPS: "Mga Tips",
    _TIPS_1: "Dapat malinaw at hindi lalampas sa 20 karakter ang pangalan.",
    _TIPS_2:
      "Dapat maglaman ng mga numero, letra, at simbolo ang password; mas kumplikado, mas mabuti.",
    _TIPS_3:
      "Ang email ay hindi maaaring mabago. Kung kailangan mong magbago, mangyaring sumulat sa support.",
    _TIPS_4:
      "Ang address ng withdrawal ay maaari lamang mabago gamit ang 2-factor authenticator na dapat naunang i-enable.",
    _ERROR_PASSWORD: "Maling kasalukuyang password",
    _SUCCESS: "Matagumpay na na-update ang profile",
    _MIN: "Minimum na withdrawal",
    _UPDATE: "I-update ang Profile",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "Nakalimutan ang iyong password?",
    _EMAIL: "Iyong email",
    _BUTTON: "Magpadala ng email",
    _MESSAGE: "Ipinadala na ang iyong password sa iyong email",
    _ERROR_EMAIL: "Hindi rehistrado ang email na ito",
  },
  _GLOBALV: {
    _TITLE: "GV",
    _CURRENT: "Kasalukuyang Ranggo",
    _NEXT: "Susunod na Ranggo",
    _SILVER: "Pilak",
    _GOLD: "Ginto",
    _RUBY: "Rubi",
    _EMERALD: "Esmeralda",
    _DIAMOND: "Diyamante",
    _DIAMONDB: "Asul na Diyamante",
    _DIAMONDBB: "Itim na Diyamante",
    _NONE: "Wala",
  },
  _AUTH: {
    _TITLEDEFAULT: "Baguhin ang withdrawal wallet",
    _TITLE: "Hakbang 1",
    _TITLE2: "Hakbang 2",
    _TITLE3: "Hakbang 3",
    _STEP1: "I-download at i-install ang authenticator sa iyong device",
    _STEP2: "I-scan ang code para mai-save",
    _FORMEMAIL: "Pag-verify ng Email",
    _FORMHELPEMAIL: "Ilagay ang 6-digit verification code na ipinadala sa",
    _FORMHELPAUTH: "Ilagay ang 6-digit na code mula sa Authenticator App",
    _FORM1: "Authenticator Pin",
    _FORM2: "Withdrawal address",
    _FORMMIN: "Ang withdrawal address ay dapat 34 karakter ang haba",
    _FORMCODESEND: "Code na ipinadala",
    _VERIFF: "I-verify",
    _ACTION: "Suriin at I-save",
    _ERROR: "Error, subukang muli.",
    _SUCCESS: "Matagumpay na naipadala",
    _RETRYOTP: "Error sa pagpapadala ng code, subukang muli.",
    _SENDOTP: "Matagumpay na naipadala ang code",
    _SUCCESSOTP: "Matagumpay na na-verify ang OTP",
    _SUCCESSAUTH2F: "Matagumpay na na-verify ang Auth2fa",
    _ERRORCODE: "Invalid na code, subukang muli",
  },
  _COUNTDOWN: {
    _TITLE: "Susunod na cut-off:",
    _DAYS: "Araw",
    _HOURS: "Oras",
    _MINUTES: "Minuto",
    _SECONDS: "Segundo",
  },
  _WITHDRAWAL: {
    _TITLE: "Mga Transaksyon ng Withdrawal",
  },
  _SYSTEM: {
    _SYSTEM: "Sistema",
    _GLOBAL: "Pandaigdigan",
    _INVESTMENT: "Bawiin ang pamumuhunan",
    _INVESTMENTTEXT:
      "Tandaan na magkakaroon ka ng parusa na 25% ng iyong pamumuhunan",
    _ACCELERATE: "Pabilisin",
    _REPORT: "Ulat sa Pamumuhunan ng Cryptotradex",
    _PARNERT: "Impormasyon ng sponsor",
  },
  _WITHDRAWINVESTMENT: {
    _TITLE: "Mag-withdraw ng Investment",
    _MESSAGE:
      "Ang pag-withdraw ng iyong kapital ngayon ay magkakaroon ng 25% na operational fee at ipoproseso sa loob ng 24 oras. Kung gagawin mo ito, agad mong pipigilan ang potensyal na paglago nito. Magpasya nang may pangmatagalang pananaw!",
    _BUTTON: "Mag-withdraw",
    _BUTTONWAIT: "Pag-withdraw sa proseso",
  },
};
