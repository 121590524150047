import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import CustomButton from '../components/CustomButton';
import BackgroundImage from "../assets/svg/login.svg"
import BgLogin from "../assets/svg/BgLogin.svg"
import CryptotradexLogo from "../assets/svg/logo.svg"
import AppContext from "../context/AppContext";

export default function Polity() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { register, handleSubmit } = useForm();
    const [showPassword, setShowPassword] = useState(false);
    const { loading, updateLoading } = useContext(AppContext);



    return (
        <div className="flex mx-auto md:h-full pt:mt-0 justify-center items-center bg-cover" style={{ backgroundImage: `url(${BgLogin})` }}>
            <div className="custom-button-shadow custom-button-shadow-two flex flex-col bg-no-repeat bg-contain bg-cover bg-center justify-center h-screen lg:h-auto items-center w-full lg:w-4/5 p-10 lg:p-10 rounded-lg shadow z-50" style={{ backgroundImage: `url(${BackgroundImage})` }}>
                <img src={CryptotradexLogo} className="w-2/6 mb-1 mx-auto" alt={"Cryptotradex"} />
                <div>
                    <div className="bg-gray-100 p-6">
                        <div className=" mx-auto bg-white p-8 rounded-lg shadow-md">
                            <h1 className="text-3xl font-bold mb-6 text-gray-900">Cryptotradex Privacy Policy</h1>
                            <p className="text-gray-700 mb-6">
                                Last Updated: 01/10/2024
                            </p>

                            <p className="text-gray-700 mb-4">
                                At Cryptotradex, we value the privacy of our users and are committed to protecting their personal information. This Privacy Policy explains how we collect, use, store, and protect your data, as well as your rights regarding your information. By joining our community and using our services, you agree to the practices described in this policy.
                            </p>

                            <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-800">1. Information We Collect</h2>
                            <p className="text-gray-700 mb-4">
                                Cryptotradex collects and processes the following personal information:
                            </p>
                            <ul className="list-disc list-inside text-gray-700 mb-4">
                                <li><strong>Email:</strong> Used to send updates, weekly summaries, and to verify and secure your account.</li>
                                <li><strong>Telegram Account:</strong> Used for community engagement and direct communication, should you choose to join our networks.</li>
                            </ul>

                            <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-800">2. Use of Your Information</h2>
                            <p className="text-gray-700 mb-4">
                                The collected information is used solely for the following purposes:
                            </p>
                            <ul className="list-disc list-inside text-gray-700 mb-4">
                                <li><strong>Weekly Updates:</strong> Every Sunday, we will send an email with weekly highlights related to Web3 projects, guides, insights, and expert perspectives.</li>
                                <li><strong>Account Security Verification:</strong> Emails are sent to verify the user's identity and maintain account security as needed.</li>
                            </ul>
                            <p className="text-gray-700 mb-4">
                                <em>Note:</em> Cryptotradex does not use your data for third-party advertising purposes and does not share your information with other partners without your explicit consent.
                            </p>

                            <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-800">3. Data Security</h2>
                            <p className="text-gray-700 mb-4">
                                Cryptotradex implements technical and organizational measures to protect your information against unauthorized access, disclosure, or misuse. However, no security system is completely infallible. If you detect any unauthorized use of your account or email, please notify us immediately so we can take appropriate action.
                            </p>

                            <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-800">4. Data Deletion and Management</h2>
                            <p className="text-gray-700 mb-4">
                                Users have the right to manage and delete their personal information at any time. To request deletion of your data, please contact our support team or use the options provided on our platform. Once the request is received, Cryptotradex will delete all records related to your account within a reasonable timeframe.
                            </p>

                            <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-800">5. Email Communications</h2>
                            <p className="text-gray-700 mb-4">
                                - <strong>Weekly Emails:</strong> To keep our users informed, we send weekly emails every Sunday with highlights and news. These emails may include updates on guides, tips, and analyses of various crypto networks.
                            </p>
                            <p className="text-gray-700 mb-4">
                                - <strong>Account Security:</strong> Occasionally, we will send emails to verify and secure your Cryptotradex account. These messages are necessary to ensure user security and data protection.
                            </p>
                            <p className="text-gray-700 mb-4">
                                <em>Important:</em> We use a third-party service, Mailgun, to manage email communications. This service ensures reliable and secure email delivery. Cryptotradex does not share any additional information with Mailgun and only uses this platform for authorized communications.
                            </p>

                            <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-800">6. Changes to the Privacy Policy</h2>
                            <p className="text-gray-700 mb-4">
                                Cryptotradex reserves the right to update this Privacy Policy at any time. We will notify you of any significant changes via email and on our website, so you are always aware of our privacy practices.
                            </p>

                            <h2 className="text-2xl font-semibold mt-6 mb-4 text-gray-800">7. Contact</h2>
                            <p className="text-gray-700 mb-4">
                                If you have any questions or concerns regarding this Privacy Policy or the treatment of your data, please contact us anytime through our website support team.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}