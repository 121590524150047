export const ko = {
  _LOGIN: {
    _EMAIL: "이메일",
    _PASSWORD: "비밀번호",
    _REMEMBER: "기억하기",
    _LOGIN: "로그인",
    _SIGNUP: "가입하기",
    _FORGOTPASSWORD: "비밀번호를 잊으셨나요?",
    _NOACCOUNT: "계정이 없으신가요?",
    _ERROR_EMAIL: "등록되지 않은 것 같습니다! 다시 시도하세요.",
    _ERROR_PASSWORD: "잘못된 비밀번호",
    _SUCCESS: "로그인 성공",
  },
  _REGISTER: {
    _REGISTER: "계정 등록",
    _NAME: "사용자 이름",
    _EMAIL: "이메일",
    _PASSWORD: "비밀번호",
    _CONFIRMPASSWORD: "비밀번호 확인",
    _WHATSAPP: "WhatsApp",
    _TELEGRAM: "텔레그램 (선택사항)",
    _HAVEACCOUNT: "이미 계정이 있습니다",
    _ERROR_REFERAL: "잘못된 추천 코드",
    _ERROR_EMAIL: "이미 존재하는 이메일입니다",
    _SUCCESS: "등록 성공",
  },
  _SIDEBAR: {
    _PAGES: "페이지",
    _OPEN: "사이드바 열기",
    _DASHBOARD: "홈",
    _NETWORK: "네트워크",
    _FINANCE: "지갑",
    _PACKAGES: "플랜",
    _SIGNOUT: "로그아웃",
    _LANGUAGE: "언어",
    _PROFILE: "프로필",
  },
  _DASHBOARD: {
    _TITLE: "잔액",
    _TITLE2: "예금",
    _TITLE3: "총 수익",
    _TITLE4: "유니레벨 수익",
    _TITLE5: "랭크 보너스",
    _TITLE6: "플랜",
    _TOTAL: "총합",
    _TABLE3: "패키지 목록",
    _START: "시작",
    _FINISH: "종료",
    _STATUS: "상태",
    _STATUS_ACTIVE: "활성",
    _STATUS_INACTIVE: "비활성",
    _CURRENT: "현재",
    _UPGRADE: "업그레이드",
    _COPYMESSAGE: "성공적으로 복사됨",
    _NOTIFICATION1: "새 예금",
    _NOTIFICATION2: "Cryptotradex 수익",
    _NOTIFICATION3: "유니레벨 수익",
    _NOTIFICATION4: "무제한 범위",
    _NOTIFICATION5: "패키지 구매",
    _NOTIFICATION7: "반복 직접 추천 보너스",
    _PREV: "이전",
    _NEXT: "다음",
    _TABLE4_A: "운영",
    _TABLE4_B: "수익",
    _TABLE4_C: "ROI",
    _TABLE4_D: "날짜",
    _SHARED: "추천 링크를 공유하세요",
  },
  _FINANCE: {
    _DEPOSIT: "예금",
    _QR: "다음 QR 코드를 스캔하거나 주소를 복사하세요",
    _NETWORK: "네트워크",
    _INSTRUCTIONS: "TRON (TRX)을 보내는 방법",
    _STEP1: "선택한 네트워크가 TRON (TRC20)인지 확인하세요.",
    _STEP2: "다음 지갑 주소로 TRON (TRX)을 보내세요.",
    _COPY: "복사",
    _DISCLAIMER:
      "면책 조항: TRON (TRC20) 네트워크 외부에서 이루어진 거래나 TRON (TRX) 외의 암호화폐 전송에 대해 우리는 책임지지 않습니다. 이러한 오류로 인한 자금 손실은 전적으로 송신자의 책임입니다.",
    _TABLE1: "예금 내역",
    _TABLE1_A: "주문",
    _TABLE1_B: "금액",
    _TABLE1_C: "날짜",
    _TABLE1_D: "상태",
    _SUCCESS: "패키지가 성공적으로 추가되었습니다",
    _COPYMESSAGE: "지갑 주소가 성공적으로 복사되었습니다",
  },
  _PACKAGES: {
    _ATTENTION:
      "주의: 당신은 기업 계정을 가지고 있습니다. 패키지를 구매하면 계정과 모든 혜택이 초기화됩니다.",
    _PACKAGES: "가장 적합한 플랜을 선택하세요",
    _SUBTITLE: "8가지 옵션이 있습니다",
    _BALANCE: "예금 잔액",
    _PACKAGE: "패키지",
    _PACKAGE_1: "당신은 레벨에서부터 수익을 얻게 됩니다",
    _PACKAGE_2: "최대 3배 수익",
    _PACKAGE_3: "일일 ROI",
    _BUTTON1: "가득 참",
    _BUTTON2: "구매",
    _CALCULATE: "플랜 계산",
    _RECHARGE: "잔액이 부족합니다. 지갑을 충전하세요",
    _PACKAGEMESSAGE: "더 높은 패키지를 구매해야 합니다",
    _SUCCESS: "패키지가 성공적으로 추가되었습니다",
    _UPDATE: "패키지 업그레이드 가격",
  },
  _NETWORK: {
    _TODAY: "오늘",
    _AVAILABLE: "사용 가능",
    _WITHDRAW: "총 인출액",
    _SEARCHLABEL: "이메일 검색",
    _SEARCH: "검색",
    _NETWORK: "네트워크",
    _DIRECTS: "직접",
    _SUCCESS: "검색 성공",
    _ERROR: "당신의 이진 트리의 일부가 아닙니다.",
    _LEVEL: "레벨",
    _USERS: "사용자",
    _AMOUNT: "금액",
    _USERNAME: "사용자 이름",
    _EMAIL: "이메일",
    _WHATSAPP: "WhatsApp",
    _PACKAGE: "패키지",
  },
  _PROFILE: {
    _TITLE: "프로필",
    _NAME: "당신의 이름",
    _EMAIL: "당신의 이메일",
    _WHATSAPP: "WhatsApp",
    _CURRENTPASSWORD: "현재 비밀번호",
    _PASSWORD: "새 비밀번호",
    _CONFIRMPASSWORD: "새 비밀번호 확인",
    _WALLET: "인출 주소",
    _ACTION: "수정",
    _TIPS: "팁",
    _TIPS_1: "이름은 읽기 쉬워야 하며 20자를 초과해서는 안됩니다.",
    _TIPS_2:
      "비밀번호는 숫자, 문자, 기호를 포함해야 하며, 복잡할수록 좋습니다.",
    _TIPS_3:
      "이메일은 변경할 수 없습니다. 변경이 필요하면 지원팀에 연락하세요.",
    _TIPS_4:
      "인출 주소는 이전에 활성화된 2단계 인증을 통해서만 수정할 수 있습니다.",
    _ERROR_PASSWORD: "이전 비밀번호 오류",
    _SUCCESS: "프로필이 성공적으로 업데이트되었습니다",
    _MIN: "최소 인출",
    _UPDATE: "프로필 업데이트",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "비밀번호를 잊으셨나요?",
    _EMAIL: "당신의 이메일",
    _BUTTON: "이메일 전송",
    _MESSAGE: "비밀번호가 이메일로 전송되었습니다",
    _ERROR_EMAIL: "이 이메일은 등록되지 않았습니다",
  },
  _GLOBALV: {
    _TITLE: "GV",
    _CURRENT: "현재 등급",
    _NEXT: "다음 등급",
    _SILVER: "실버",
    _GOLD: "골드",
    _RUBY: "루비",
    _EMERALD: "에메랄드",
    _DIAMOND: "다이아몬드",
    _DIAMONDB: "블루 다이아몬드",
    _DIAMONDBB: "블랙 다이아몬드",
    _NONE: "없음",
  },
  _AUTH: {
    _TITLEDEFAULT: "인출 지갑 변경",
    _TITLE: "1단계",
    _TITLE2: "2단계",
    _TITLE3: "3단계",
    _STEP1: "기기에 인증 앱을 다운로드하고 설치하세요",
    _STEP2: "코드를 스캔하여 저장하세요",
    _FORMEMAIL: "이메일 인증",
    _FORMHELPEMAIL: "보낸 6자리 인증 코드를 입력하세요",
    _FORMHELPAUTH: "인증 앱에서 생성된 6자리 코드를 입력하세요",
    _FORM1: "인증 앱 코드",
    _FORM2: "인출 주소",
    _FORMMIN: "인출 주소는 34자리여야 합니다",
    _FORMCODESEND: "코드 전송 완료",
    _VERIFF: "검증",
    _ACTION: "검증 및 저장",
    _ERROR: "오류가 발생했습니다. 다시 시도하세요.",
    _SUCCESS: "성공적으로 변경되었습니다",
    _RETRYOTP: "코드 전송 오류, 다시 시도하세요.",
    _SENDOTP: "코드가 성공적으로 전송되었습니다",
    _SUCCESSOTP: "OTP가 성공적으로 확인되었습니다",
    _SUCCESSAUTH2F: "2단계 인증이 성공적으로 확인되었습니다",
    _ERRORCODE: "잘못된 코드, 다시 시도하세요",
  },
  _COUNTDOWN: {
    _TITLE: "다음 컷오프:",
    _DAYS: "일",
    _HOURS: "시간",
    _MINUTES: "분",
    _SECONDS: "초",
  },
  _WITHDRAWAL: {
    _TITLE: "인출 거래",
  },
  _SYSTEM: {
    _SYSTEM: "시스템",
    _GLOBAL: "글로벌",
    _INVESTMENT: "투자 철회",
    _INVESTMENTTEXT: "투자의 25%가 벌금으로 부과된다는 점을 기억하세요",
    _ACCELERATE: "가속",
    _REPORT: "Cryptotradex 투자 보고서",
    _PARNERT: "스폰서 정보",
  },
  _WITHDRAWINVESTMENT: {
    _TITLE: "투자 인출",
    _MESSAGE:
      "지금 자본을 인출하면 25%의 운영 수수료가 부과되며 24시간 이내에 처리됩니다. 그렇게 하면 성장 가능성이 즉시 중단됩니다. 장기적인 안목으로 결정하세요!",
    _BUTTON: "인출",
    _BUTTONWAIT: "인출 진행 중",
  },
};
