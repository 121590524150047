export const sr = {
  _LOGIN: {
    _EMAIL: "Email",
    _PASSWORD: "Lozinka",
    _REMEMBER: "Zapamti me",
    _LOGIN: "PRIJAVA",
    _SIGNUP: "Registruj se",
    _FORGOTPASSWORD: "Zaboravili ste lozinku?",
    _NOACCOUNT: "Nemate nalog?",
    _ERROR_EMAIL: "Izgleda da niste registrovani! Pokušajte ponovo.",
    _ERROR_PASSWORD: "Pogrešna lozinka",
    _SUCCESS: "Uspješna prijava",
  },
  _REGISTER: {
    _REGISTER: "Registracija naloga",
    _NAME: "Korisničko ime",
    _EMAIL: "Vaš email",
    _PASSWORD: "Vaša lozinka",
    _CONFIRMPASSWORD: "Potvrdi lozinku",
    _WHATSAPP: "WhatsApp",
    _TELEGRAM: "Telegram (opciono)",
    _HAVEACCOUNT: "Već imam nalog",
    _ERROR_REFERAL: "Pogrešan referal kod",
    _ERROR_EMAIL: "Ova email adresa već postoji",
    _SUCCESS: "Uspješna registracija",
  },
  _SIDEBAR: {
    _PAGES: "Stranice",
    _OPEN: "Otvorite bočnu traku",
    _DASHBOARD: "Početna",
    _NETWORK: "Mreža",
    _FINANCE: "Novčanik",
    _PACKAGES: "Planovi",
    _SIGNOUT: "Odjavi se",
    _LANGUAGE: "Jezik",
    _PROFILE: "Profil",
  },
  _DASHBOARD: {
    _TITLE: "Bilans",
    _TITLE2: "Depozit",
    _TITLE3: "Ukupna zarada",
    _TITLE4: "Unilevel zarada",
    _TITLE5: "Bonus za rang",
    _TITLE6: "Plan",
    _TOTAL: "Ukupno",
    _TABLE3: "Lista paketa",
    _START: "Početak",
    _FINISH: "Završetak",
    _STATUS: "Status",
    _STATUS_ACTIVE: "Aktivan",
    _STATUS_INACTIVE: "Neaktivan",
    _CURRENT: "Trenutno",
    _UPGRADE: "Nadogradite",
    _COPYMESSAGE: "Uspešno kopirano",
    _NOTIFICATION1: "Novi depozit",
    _NOTIFICATION2: "Cryptotradex profit",
    _NOTIFICATION3: "Unilevel profit",
    _NOTIFICATION4: "Opseg beskonačan",
    _NOTIFICATION5: "Kupovina paketa",
    _NOTIFICATION7: "Повратни бонус за директну препоруку",
    _PREV: "Prethodno",
    _NEXT: "Sledeće",
    _TABLE4_A: "Operacija",
    _TABLE4_B: "Profit",
    _TABLE4_C: "ROI",
    _TABLE4_D: "Datum",
    _SHARED: "Podelite vašu referalnu vezu",
  },
  _FINANCE: {
    _DEPOSIT: "Depozit",
    _QR: "Skenirajte sledeći QR ili kopirajte adresu",
    _NETWORK: "Mreža",
    _INSTRUCTIONS: "Uputstva za slanje TRON (TRX)",
    _STEP1: "Uverite se da je odabrana mreža TRON (TRC20).",
    _STEP2: "Pošaljite iznos TRON (TRX) na sledeću adresu novčanika.",
    _COPY: "Kopiraj",
    _DISCLAIMER:
      "Odricanje od odgovornosti: Nismo odgovorni za transakcije izvršene izvan TRON (TRC20) mreže ili za slanje kriptovaluta osim TRON (TRX). Bilo kakav gubitak sredstava zbog ovih grešaka biće isključiva odgovornost pošiljaoca.",
    _TABLE1: "Istorija depozita",
    _TABLE1_A: "Porudžbina",
    _TABLE1_B: "Iznos",
    _TABLE1_C: "Datum",
    _TABLE1_D: "Status",
    _SUCCESS: "Paket je uspešno dodat",
    _COPYMESSAGE: "Adresa novčanika uspešno kopirana",
  },
  _PACKAGES: {
    _ATTENTION:
      "Pažnja: Imate korporativni nalog. Kupovinom paketa, ponovo ćete pokrenuti nalog i sve njegove prednosti.",
    _PACKAGES: "Izaberite najbolji plan za vas",
    _SUBTITLE: "Imate 8 opcija",
    _BALANCE: "Bilans depozita",
    _PACKAGE: "Paket ",
    _PACKAGE_1: "Zarađujete do nivoa ",
    _PACKAGE_2: "Zarađujte do 3X",
    _PACKAGE_3: "Dnevni ROI",
    _BUTTON1: "Ispunjeno",
    _BUTTON2: "Kupi",
    _CALCULATE: "Izračunaj plan",
    _RECHARGE: "Nemate dovoljno novca, dopunite svoj novčanik",
    _PACKAGEMESSAGE: "Morate kupiti viši paket",
    _SUCCESS: "Paket je uspešno dodat",
    _UPDATE: "Cena nadogradnje paketa",
  },
  _NETWORK: {
    _TODAY: "Danas",
    _AVAILABLE: "Dostupno",
    _WITHDRAW: "Ukupno povučeno",
    _SEARCHLABEL: "Pretraga e-pošte",
    _SEARCH: "Pretraži",
    _NETWORK: "Mreža",
    _DIRECTS: "Direktni",
    _SUCCESS: "Pretraga uspešna",
    _ERROR: "Nije deo vašeg binarnog stabla.",
    _LEVEL: "Nivo",
    _USERS: "Korisnici",
    _AMOUNT: "Iznos",
    _USERNAME: "Korisničko ime",
    _EMAIL: "Email",
    _WHATSAPP: "WhatsApp",
    _PACKAGE: "Paket",
  },
  _PROFILE: {
    _TITLE: "Profil",
    _NAME: "Vaše ime",
    _EMAIL: "Vaša email adresa",
    _WHATSAPP: "WhatsApp",
    _CURRENTPASSWORD: "Trenutna lozinka",
    _PASSWORD: "Nova lozinka",
    _CONFIRMPASSWORD: "Potvrdi novu lozinku",
    _WALLET: "Adresa za povlačenje",
    _ACTION: "Izmeni",
    _TIPS: "Saveti",
    _TIPS_1: "Ime mora biti čitljivo i ne sme prelaziti 20 karaktera.",
    _TIPS_2:
      "Lozinka mora uključivati brojeve, slova i simbole; što je složenija, to je bolje.",
    _TIPS_3:
      "Email se ne može promeniti. Ako treba da izvršite promenu, molimo vas da pišete podršci.",
    _TIPS_4:
      "Adresa za povlačenje može se promeniti samo uz dvofaktornu autentifikaciju, koja mora biti prethodno omogućena.",
    _ERROR_PASSWORD: "Greška u prethodnoj lozinci",
    _SUCCESS: "Profil je uspešno ažuriran",
    _MIN: "Minimalno povlačenje",
    _UPDATE: "Ažuriraj profil",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "Zaboravili ste lozinku?",
    _EMAIL: "Vaša email adresa",
    _BUTTON: "Pošalji email",
    _MESSAGE: "Vaša lozinka je poslana na vašu email adresu",
    _ERROR_EMAIL: "Ova email adresa nije registrovana",
  },
  _GLOBALV: {
    _TITLE: "GV",
    _CURRENT: "Trenutni rang",
    _NEXT: "Sledeći rang",
    _SILVER: "Srebro",
    _GOLD: "Zlato",
    _RUBY: "Rubin",
    _EMERALD: "Smaragd",
    _DIAMOND: "Dijamant",
    _DIAMONDB: "Dijamant Plavi",
    _DIAMONDBB: "Dijamant Crni",
    _NONE: "Nema",
  },
  _AUTH: {
    _TITLEDEFAULT: "Promena adrese za povlačenje",
    _TITLE: "Korak 1",
    _TITLE2: "Korak 2",
    _TITLE3: "Korak 3",
    _STEP1: "Preuzmite i instalirajte autentifikator na vašem uređaju",
    _STEP2: "Skenirajte kod da sačuvate",
    _FORMEMAIL: "Verifikacija email-a",
    _FORMHELPEMAIL: "Unesite 6-cifreni verifikacioni kod poslat na",
    _FORMHELPAUTH:
      "Unesite 6-cifreni kod generisan od strane aplikacije autentifikator",
    _FORM1: "Pin od autentifikatora",
    _FORM2: "Adresa za povlačenje",
    _FORMMIN: "Adresa za povlačenje mora imati 34 karaktera",
    _FORMCODESEND: "Pošalji kod",
    _VERIFF: "Veriff",
    _ACTION: "Proveri i sačuvaj",
    _ERROR: "Greška, pokušajte ponovo.",
    _SUCCESS: "Promena uspešna",
    _RETRYOTP: "Greška pri slanju koda, pokušajte ponovo.",
    _SENDOTP: "Kod uspešno poslat",
    _SUCCESSOTP: "OTP uspešno potvrđen",
    _SUCCESSAUTH2F: "Auth2fa uspešno potvrđen",
    _ERRORCODE: "Nevažeći kod, pokušajte ponovo",
  },
  _COUNTDOWN: {
    _TITLE: "Sledeći prekid:",
    _DAYS: "Dani",
    _HOURS: "Sati",
    _MINUTES: "Minuti",
    _SECONDS: "Sekunde",
  },
  _WITHDRAWAL: {
    _TITLE: "Transakcije povlačenja",
  },
  _SYSTEM: {
    _SYSTEM: "Sistem",
    _GLOBAL: "Globalno",
    _INVESTMENT: "Povuci investiciju",
    _INVESTMENTTEXT: "Zapamtite da ćete imati kaznu od 25% od vaše investicije",
    _ACCELERATE: "Ubrzaj",
    _REPORT: "Izveštaj o investicijama Cryptotradex",
    _PARNERT: "Информације о спонзору",
  },
  _WITHDRAWINVESTMENT: {
    _TITLE: "Povlačenje investicije",
    _MESSAGE:
      "Povlačenje vašeg kapitala sada podrazumeva operativnu naknadu od 25% i biće obrađeno u roku od 24 sata. Ako to uradite, odmah ćete zaustaviti njegov potencijal rasta. Odlučite sa dugoročnom vizijom!",
    _BUTTON: "Povucite",
    _BUTTONWAIT: "Povlačenje u toku",
  },
};
