export const mk = {
  _LOGIN: {
    _EMAIL: "Е-пошта",
    _PASSWORD: "Лозинка",
    _REMEMBER: "Запомни ме",
    _LOGIN: "ВЛЕЗ",
    _SIGNUP: "Регистрирај се",
    _FORGOTPASSWORD: "Заборавивте ја лозинката?",
    _NOACCOUNT: "Немате акаунт?",
    _ERROR_EMAIL: "Очигледно не сте регистрирани! Обидете се повторно.",
    _ERROR_PASSWORD: "Невалидна лозинка",
    _SUCCESS: "Успешно пријавување",
  },
  _REGISTER: {
    _REGISTER: "Регистрирајте акаунт",
    _NAME: "Корисничко име",
    _EMAIL: "Вашата е-пошта",
    _PASSWORD: "Вашата лозинка",
    _CONFIRMPASSWORD: "Потврди лозинка",
    _WHATSAPP: "WhatsApp",
    _TELEGRAM: "Telegram (опционално)",
    _HAVEACCOUNT: "Имам акаунт",
    _ERROR_REFERAL: "Невалиден реферален код",
    _ERROR_EMAIL: "Оваа е-пошта веќе постои",
    _SUCCESS: "Успешна регистрација",
  },
  _SIDEBAR: {
    _PAGES: "Страници",
    _OPEN: "Отвори странична лента",
    _DASHBOARD: "Дома",
    _NETWORK: "Мрежа",
    _FINANCE: "Паричник",
    _PACKAGES: "Планови",
    _SIGNOUT: "Одјави се",
    _LANGUAGE: "Јазик",
    _PROFILE: "Профил",
  },
  _DASHBOARD: {
    _TITLE: "Баланс",
    _TITLE2: "Депозит",
    _TITLE3: "Вкупни заработувања",
    _TITLE4: "Универзални заработувања",
    _TITLE5: "Ранги бонус",
    _TITLE6: "План",
    _TOTAL: "Вкупно",
    _TABLE3: "Листа на пакети",
    _START: "Почеток",
    _FINISH: "Завршеток",
    _STATUS: "Статус",
    _STATUS_ACTIVE: "Активен",
    _STATUS_INACTIVE: "Неактивен",
    _CURRENT: "Тековен",
    _UPGRADE: "Подобри",
    _COPYMESSAGE: "Успешно копирано",
    _NOTIFICATION1: "Нов депозит",
    _NOTIFICATION2: "Cryptotradex профит",
    _NOTIFICATION3: "Универзален профит",
    _NOTIFICATION4: "Опсег бесконечен",
    _NOTIFICATION5: "Купи пакет",
    _NOTIFICATION7: "Повторувачки директен бонус за препорака",
    _PREV: "Претходно",
    _NEXT: "Следно",
    _TABLE4_A: "Операција",
    _TABLE4_B: "Профит",
    _TABLE4_C: "ROI",
    _TABLE4_D: "Датум",
    _SHARED: "Споделете ја вашата реферална врска",
  },
  _FINANCE: {
    _DEPOSIT: "Депозит",
    _QR: "Скенирајте го следниот QR или копирајте ја адресата",
    _NETWORK: "Мрежа",
    _INSTRUCTIONS: "Упатства за испраќање TRON (TRX)",
    _STEP1: "Убедете се дека избраната мрежа е TRON (TRC20).",
    _STEP2:
      "Испратете го износот на TRON (TRX) на следната адреса на паричникот.",
    _COPY: "Копирај",
    _DISCLAIMER:
      "Одговорност: Ние не сме одговорни за трансакции направени надвор од TRON (TRC20) мрежата или за испраќање криптовалути освен TRON (TRX). Секое губење на средства поради овие грешки ќе биде целосна одговорност на испраќачот.",
    _TABLE1: "Историја на депозити",
    _TABLE1_A: "Нарачка",
    _TABLE1_B: "Износ",
    _TABLE1_C: "Датум",
    _TABLE1_D: "Статус",
    _SUCCESS: "Пакетот беше успешно додаден",
    _COPYMESSAGE: "Адресата на паричникот успешно копирана",
  },
  _PACKAGES: {
    _ATTENTION:
      "Внимание: Имате корпоративен акаунт. Со купување пакет, ќе го рестартирате акаунтот и сите негови предности.",
    _PACKAGES: "Изберете го најдобриот план за вас",
    _SUBTITLE: "Имате 8 опции",
    _BALANCE: "Баланс Депозит",
    _PACKAGE: "Пакет ",
    _PACKAGE_1: "Ќе заработите до ниво ",
    _PACKAGE_2: "Заработете до 3X",
    _PACKAGE_3: "Дневен ROI",
    _BUTTON1: "Исполнето",
    _BUTTON2: "Купи",
    _CALCULATE: "Пресметај план",
    _RECHARGE: "Немате доволно средства, наплатете го вашиот паричник",
    _PACKAGEMESSAGE: "Мора да купите повисок пакет",
    _SUCCESS: "Пакетот беше успешно додаден",
    _UPDATE: "Цена за надградба на пакетот",
  },
  _NETWORK: {
    _TODAY: "Денес",
    _AVAILABLE: "Достапно",
    _WITHDRAW: "Вкупно повлечено",
    _SEARCHLABEL: "Пребарај е-пошта",
    _SEARCH: "Пребарај",
    _NETWORK: "Мрежа",
    _DIRECTS: "Директни",
    _SUCCESS: "Успешно пребарување",
    _ERROR: "Не е дел од вашето бинарно дрво.",
    _LEVEL: "Ниво",
    _USERS: "Корисници",
    _AMOUNT: "Износ",
    _USERNAME: "Корисничко име",
    _EMAIL: "Е-пошта",
    _WHATSAPP: "WhatsApp",
    _PACKAGE: "Пакет",
  },
  _PROFILE: {
    _TITLE: "Профил",
    _NAME: "Вашето име",
    _EMAIL: "Вашата е-пошта",
    _WHATSAPP: "WhatsApp",
    _CURRENTPASSWORD: "Тековна лозинка",
    _PASSWORD: "Нова лозинка",
    _CONFIRMPASSWORD: "Потврди нова лозинка",
    _WALLET: "Адреса за повлекување",
    _ACTION: "Уреди",
    _TIPS: "Совети",
    _TIPS_1: "Името мора да биде читливо и да не надминува 20 знаци.",
    _TIPS_2:
      "Лозинката мора да вклучува броеви, букви и симболи; колку е потешка, толку е подобро.",
    _TIPS_3:
      "Е-поштата не може да се промени. Ако треба да направите промена, ве молиме пишете до поддршката.",
    _TIPS_4:
      "Адресата за повлекување може да се модифицира само со двофакторен аутентификатор, кој мора да биде претходно активиран.",
    _ERROR_PASSWORD: "Грешка во претходната лозинка",
    _SUCCESS: "Профилот беше успешно ажуриран",
    _MIN: "Минимално повлекување",
    _UPDATE: "Ажурирај профил",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "Заборавивте ја лозинката?",
    _EMAIL: "Вашата е-пошта",
    _BUTTON: "Испрати е-пошта",
    _MESSAGE: "Вашата лозинка беше испратена на вашата е-пошта",
    _ERROR_EMAIL: "Оваа е-пошта не е регистрирана",
  },
  _GLOBALV: {
    _TITLE: "ГВ",
    _CURRENT: "Тековен ранг",
    _NEXT: "Следен ранг",
    _SILVER: "Сребро",
    _GOLD: "Злато",
    _RUBY: "Рубин",
    _EMERALD: "Изумруд",
    _DIAMOND: "Дијамант",
    _DIAMONDB: "Дијамант Блу",
    _DIAMONDBB: "Дијамант Црн",
    _NONE: "Нема",
  },
  _AUTH: {
    _TITLEDEFAULT: "Промена на адресата за повлекување",
    _TITLE: "Чекор 1",
    _TITLE2: "Чекор 2",
    _TITLE3: "Чекор 3",
    _STEP1: "Преземете и инсталирајте го аутентификаторот на вашиот уред",
    _STEP2: "Скенирајте го кодот за да го зачувате",
    _FORMEMAIL: "Верификација на е-пошта",
    _FORMHELPEMAIL: "Внесете го 6-цифрениот код за верификација испратен на",
    _FORMHELPAUTH:
      "Внесете го 6-цифрениот код генериран од Аутентификатор Апликацијата",
    _FORM1: "Пин од Аутентификатор",
    _FORM2: "Адреса за повлекување",
    _FORMMIN: "Адресата за повлекување мора да има 34 знаци",
    _FORMCODESEND: "Испрати код",
    _VERIFF: "Veriff",
    _ACTION: "Проверете и зачувајте",
    _ERROR: "Грешка, обидете се повторно.",
    _SUCCESS: "Промена успешна",
    _RETRYOTP: "Грешка при испраќање на кодот, обидете се повторно.",
    _SENDOTP: "Кодот успешно испратен",
    _SUCCESSOTP: "OTP успешно потврден",
    _SUCCESSAUTH2F: "Auth2fa успешно потврден",
    _ERRORCODE: "Невалиден код, обидете се повторно",
  },
  _COUNTDOWN: {
    _TITLE: "Следно пресек:",
    _DAYS: "Дена",
    _HOURS: "Часови",
    _MINUTES: "Минути",
    _SECONDS: "Секунди",
  },
  _WITHDRAWAL: {
    _TITLE: "Трансакции за повлекување",
  },
  _SYSTEM: {
    _SYSTEM: "Систем",
    _GLOBAL: "Глобален",
    _INVESTMENT: "Повлечи инвестиција",
    _INVESTMENTTEXT:
      "Запомнете дека ќе имате казна од 25% од вашата инвестиција",
    _ACCELERATE: "Убрзајте",
    _REPORT: "Извештај за инвестиции Cryptotradex",
    _PARNERT: "Информации за спонзор",
  },
  _WITHDRAWINVESTMENT: {
    _TITLE: "Повлечи инвестиција",
    _MESSAGE:
      "Повлекувањето на вашиот капитал сега подразбира оперативна такса од 25% и ќе биде обработено во рок од 24 часа. Ако го направите тоа, веднаш ќе го запрете потенцијалот за раст. Одлучете со долгорочна визија!",
    _BUTTON: "Повлечи",
    _BUTTONWAIT: "Повлекувањето е во процес",
  },
};
