export const ita = {
  _LOGIN: {
    _EMAIL: "Email",
    _PASSWORD: "Password",
    _REMEMBER: "Ricordami",
    _LOGIN: "ACCEDI",
    _SIGNUP: "Registrati",
    _FORGOTPASSWORD: "Hai dimenticato la password?",
    _NOACCOUNT: "Non hai un account?",
    _ERROR_EMAIL: "Sembra che tu non sia registrato! Riprova.",
    _ERROR_PASSWORD: "Password errata",
    _SUCCESS: "Accesso effettuato con successo",
  },
  _REGISTER: {
    _REGISTER: "Crea un account",
    _NAME: "Nome utente",
    _EMAIL: "La tua email",
    _PASSWORD: "La tua password",
    _CONFIRMPASSWORD: "Conferma la password",
    _WHATSAPP: "WhatsApp",
    _TELEGRAM: "Telegram (opzionale)",
    _HAVEACCOUNT: "Ho già un account",
    _ERROR_REFERAL: "Codice referral errato",
    _ERROR_EMAIL: "Questa email esiste già",
    _SUCCESS: "Registrazione avvenuta con successo",
  },
  _SIDEBAR: {
    _PAGES: "Pagine",
    _OPEN: "Apri barra laterale",
    _DASHBOARD: "Home",
    _NETWORK: "Rete",
    _FINANCE: "Portafoglio",
    _PACKAGES: "Piani",
    _SIGNOUT: "Disconnettiti",
    _LANGUAGE: "Lingua",
    _PROFILE: "Profilo",
  },
  _DASHBOARD: {
    _TITLE: "Saldo",
    _TITLE2: "Deposito",
    _TITLE3: "Guadagni totali",
    _TITLE4: "Guadagni Unilevel",
    _TITLE5: "Bonus di rango",
    _TITLE6: "Piano",
    _TOTAL: "Totale",
    _TABLE3: "Lista pacchetti",
    _START: "Inizio",
    _FINISH: "Fine",
    _STATUS: "Stato",
    _STATUS_ACTIVE: "Attivo",
    _STATUS_INACTIVE: "Inattivo",
    _CURRENT: "Corrente",
    _UPGRADE: "Aggiorna",
    _COPYMESSAGE: "Copiato con successo",
    _NOTIFICATION1: "Nuovo deposito",
    _NOTIFICATION2: "Profitto da Cryptotradex",
    _NOTIFICATION3: "Profitto Unilevel",
    _NOTIFICATION4: "Gamma infinita",
    _NOTIFICATION5: "Acquista pacchetto",
    _NOTIFICATION7: "Bonus Ricorrente di Riferimento Diretto",
    _PREV: "Precedente",
    _NEXT: "Successivo",
    _TABLE4_A: "Operazione",
    _TABLE4_B: "Profitto",
    _TABLE4_C: "ROI",
    _TABLE4_D: "Data",
    _SHARED: "Condividi il tuo link di referral",
  },
  _FINANCE: {
    _DEPOSIT: "Deposito",
    _QR: "Scansiona il seguente QR o copia l'indirizzo",
    _NETWORK: "Rete",
    _INSTRUCTIONS: "Istruzioni per l'invio di TRON (TRX)",
    _STEP1: "Assicurati che la rete selezionata sia TRON (TRC20).",
    _STEP2:
      "Invia la quantità di TRON (TRX) al seguente indirizzo di portafoglio.",
    _COPY: "Copia",
    _DISCLAIMER:
      "Disclaimer: Non siamo responsabili per le transazioni effettuate al di fuori della rete TRON (TRC20) o per l'invio di criptovalute diverse da TRON (TRX). Qualsiasi perdita di fondi a causa di questi errori sarà esclusiva responsabilità del mittente.",
    _TABLE1: "Storico dei depositi",
    _TABLE1_A: "Ordine",
    _TABLE1_B: "Importo",
    _TABLE1_C: "Data",
    _TABLE1_D: "Stato",
    _SUCCESS: "Pacchetto aggiunto con successo",
    _COPYMESSAGE: "Indirizzo del portafoglio copiato con successo",
  },
  _PACKAGES: {
    _ATTENTION:
      "Attenzione: Hai un account aziendale. Acquistando un pacchetto, ripristinerai l'account e tutti i suoi benefici.",
    _PACKAGES: "Scegli il miglior piano per te",
    _SUBTITLE: "Hai 8 opzioni",
    _BALANCE: "Saldo del deposito",
    _PACKAGE: "Pacchetto",
    _PACKAGE_1: "Guadagnerai dal livello",
    _PACKAGE_2: "Guadagna fino a 3 volte",
    _PACKAGE_3: "ROI giornaliero",
    _BUTTON1: "Pieno",
    _BUTTON2: "Compra",
    _CALCULATE: "Calcola piano",
    _RECHARGE: "Non hai abbastanza denaro, ricarica il tuo portafoglio",
    _PACKAGEMESSAGE: "Devi acquistare un pacchetto più alto",
    _SUCCESS: "Pacchetto aggiunto con successo",
    _UPDATE: "Prezzo di aggiornamento del pacchetto",
  },
  _NETWORK: {
    _TODAY: "Oggi",
    _AVAILABLE: "Disponibile",
    _WITHDRAW: "Totale ritirato",
    _SEARCHLABEL: "Cerca email",
    _SEARCH: "Cerca",
    _NETWORK: "Rete",
    _DIRECTS: "Diretti",
    _SUCCESS: "Ricerca riuscita",
    _ERROR: "Non fa parte del tuo albero binario.",
    _LEVEL: "Livello",
    _USERS: "Utenti",
    _AMOUNT: "Importo",
    _USERNAME: "Nome utente",
    _EMAIL: "Email",
    _WHATSAPP: "WhatsApp",
    _PACKAGE: "Pacchetto",
  },
  _PROFILE: {
    _TITLE: "Profilo",
    _NAME: "Il tuo nome",
    _EMAIL: "La tua email",
    _WHATSAPP: "WhatsApp",
    _CURRENTPASSWORD: "Password attuale",
    _PASSWORD: "Nuova password",
    _CONFIRMPASSWORD: "Conferma nuova password",
    _WALLET: "Indirizzo di prelievo",
    _ACTION: "Modifica",
    _TIPS: "Suggerimenti",
    _TIPS_1: "Il nome deve essere leggibile e non superare i 20 caratteri.",
    _TIPS_2:
      "La password deve includere numeri, lettere e simboli; più è complessa, meglio è.",
    _TIPS_3:
      "L'email non può essere modificata. Se hai bisogno di fare un cambiamento, contatta il supporto.",
    _TIPS_4:
      "L'indirizzo di prelievo può essere modificato solo con un'autenticazione a 2 fattori, che deve essere abilitata in precedenza.",
    _ERROR_PASSWORD: "Errore password precedente",
    _SUCCESS: "Profilo aggiornato con successo",
    _MIN: "Prelievo minimo",
    _UPDATE: "Aggiorna profilo",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "Hai dimenticato la password?",
    _EMAIL: "La tua email",
    _BUTTON: "Invia email",
    _MESSAGE: "La tua password è stata inviata al tuo indirizzo email",
    _ERROR_EMAIL: "Questa email non è registrata",
  },
  _GLOBALV: {
    _TITLE: "GV",
    _CURRENT: "Rango attuale",
    _NEXT: "Prossimo rango",
    _SILVER: "Argento",
    _GOLD: "Oro",
    _RUBY: "Rubino",
    _EMERALD: "Smeraldo",
    _DIAMOND: "Diamante",
    _DIAMONDB: "Diamante Blu",
    _DIAMONDBB: "Diamante Nero",
    _NONE: "Nessuno",
  },
  _AUTH: {
    _TITLEDEFAULT: "Cambia portafoglio di prelievo",
    _TITLE: "Fase 1",
    _TITLE2: "Fase 2",
    _TITLE3: "Fase 3",
    _STEP1: "Scarica e installa l'autenticatore sul tuo dispositivo",
    _STEP2: "Scansiona il codice per salvare",
    _FORMEMAIL: "Verifica email",
    _FORMHELPEMAIL: "Inserisci il codice di verifica a 6 cifre inviato a",
    _FORMHELPAUTH:
      "Inserisci il codice a 6 cifre generato dall'app Authenticator",
    _FORM1: "Pin dell'Authenticator",
    _FORM2: "Indirizzo di prelievo",
    _FORMMIN: "L'indirizzo di prelievo deve essere di 34 caratteri",
    _FORMCODESEND: "Codice inviato",
    _VERIFF: "Verifica",
    _ACTION: "Verifica e salva",
    _ERROR: "Errore, riprova.",
    _SUCCESS: "Modifica avvenuta con successo",
    _RETRYOTP: "Errore nell'invio del codice, riprova.",
    _SENDOTP: "Codice inviato con successo",
    _SUCCESSOTP: "OTP verificato con successo",
    _SUCCESSAUTH2F: "Auth2fa verificato con successo",
    _ERRORCODE: "Codice non valido, riprova",
  },
  _COUNTDOWN: {
    _TITLE: "Prossimo taglio:",
    _DAYS: "Giorni",
    _HOURS: "Ore",
    _MINUTES: "Minuti",
    _SECONDS: "Secondi",
  },
  _WITHDRAWAL: {
    _TITLE: "Transazioni di prelievo",
  },
  _SYSTEM: {
    _SYSTEM: "Sistema",
    _GLOBAL: "Globale",
    _INVESTMENT: "Ritirare l'investimento",
    _INVESTMENTTEXT:
      "Ricorda che avrai una penalità del 25% del tuo investimento",
    _ACCELERATE: "Accelerare",
    _REPORT: "Rapporto di investimento Cryptotradex",
    _PARNERT: "Informazioni sullo sponsor",
  },
  _WITHDRAWINVESTMENT: {
    _TITLE: "Ritira Investimento",
    _MESSAGE:
      "Ritirare il tuo capitale ora comporta una commissione operativa del 25% e sarà elaborato entro 24 ore. Se lo fai, fermerai immediatamente il suo potenziale di crescita. Decidi con una visione a lungo termine!",
    _BUTTON: "Ritira",
    _BUTTONWAIT: "Ritiro in corso",
  },
};
