import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from 'react-router-dom';
import toast from "react-hot-toast";
import AppContext from "../context/AppContext";

export default function Withdrawinvestment() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [buttondisabled, setbuttondisabled] = useState(false);
    const { withdrawwinvestment, showWithdraw, updateLoading } = useContext(AppContext);

    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.add('overflow-y-hidden');
        return () => {
            document.body.classList.remove('overflow-y-hidden');
        };
    }, []);

    const closeWithdraw = () => {
        showWithdraw(false);
    }



    const requestDeleted = () => {
        updateLoading(true);
        setbuttondisabled(true);
        fetch(`${process.env.REACT_APP_BACKEND}/withdrawal-request-fee`, {
            method: "GET",
            headers: {
                Authorization: `${localStorage.getItem("token")}`,
            },
        })
            .then((response) => response.json())
            .then((response) => {
                toast.success(t("_AUTH._SUCCESS"));
                navigate("/");
                closeWithdraw()
                updateLoading(false);
            })
            .catch((error) => {
                console.log(error);
                updateLoading(false);
            });
    };

    return (
        <>
            <div className="w-screen absolute h-full" style={{ zIndex: "100", backgroundColor: "#00000091" }}>
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  custom-button-shadow-two rounded p-10 bg-zinc-800 w-11/12 lg:w-3/5">
                    <div className="flex justify-end cursor-pointer" onClick={() => closeWithdraw()}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6 text-lime-300 text-end">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                    </div>
                    <div className="flex flex-col w-full justify-center lg:justify-left items-center lg:gap-4">
                        <div className="text-lime-300">{t("_WITHDRAWINVESTMENT._TITLE")}</div>
                        <p className="text-white">{t("_WITHDRAWINVESTMENT._MESSAGE")}</p>
                        {!buttondisabled ?
                            <>
                                <div className="w-full p-2 border rounded-lg mt-4 hover:bg-red-500  text-white hover:text-black hover:border-neutral-950 cursor-pointer text-center" onClick={() => requestDeleted()} >{t("_WITHDRAWINVESTMENT._TITLE")}</div>
                            </>
                            :
                            <>
                                <div className="w-full p-2 border rounded-lg mt-4 text-center opacity-10 cursor-not-allowed text-white" >
                                    {t("_WITHDRAWINVESTMENT._BUTTONWAIT")}
                                </div>
                            </>}
                    </div>
                </div>
            </div>
        </>
    )
}
