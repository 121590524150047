import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { eng } from "./en";
import { esp } from "./es";
import { fra } from "./fr";
import { por } from "./pr";
import { chi } from "./ch";
import { hin } from "./hn";
import { rus } from "./rs";
import { ita } from "./it";
import { vie } from "./vi";
import { fil } from "./fi";
import { ar } from "./ar";
import { ko } from "./ko";
import { de } from "./de";
import { ro } from "./ro";
import { th } from "./th";
import { mk } from "./mk";
import { sl } from "./sl";
import { sr } from "./sr";
i18n.use(initReactI18next).init({
  resources: {
    Arabe: {
      //*Arabe
      translation: ar,
    },
    Chinese: {
      //*Mandarin
      translation: chi,
    },
    Aleman: {
      //*Aleman
      translation: de,
    },
    English: {
      //*Ingles
      translation: eng,
    },
    Spanish: {
      //*Español
      translation: esp,
    },
    Filipino: {
      //*Tagalo Filipino
      translation: fil,
    },
    French: {
      //*Frances
      translation: fra,
    },
    Hindi: {
      //*Hindi
      translation: hin,
    },
    Italian: {
      //*Italiano
      translation: ita,
    },
    Koreano: {
      //*Italiano
      translation: ko,
    },
    Macedoneo: {
      //*Macedonio
      translation: mk,
    },
    Portuguese: {
      //*Portugues
      translation: por,
    },
    Rumano: {
      //*Rumano
      translation: ro,
    },
    Russian: {
      //*Ruso
      translation: rus,
    },
    Slovenia: {
      //*Slovenia
      translation: sl,
    },
    Serbia: {
      //*Serbia
      translation: sr,
    },
    Tailandes: {
      //*Tailandes
      translation: th,
    },
    Vietnamese: {
      //*Vietnamese
      translation: vie,
    },
  },
  lng: localStorage.getItem("lng") || "English",
  interpolation: {
    escapeValue: false,
  },
});
export default i18n;
