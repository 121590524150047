export const esp = {
  _LOGIN: {
    _EMAIL: "Correo electrónico",
    _PASSWORD: "Contraseña",
    _REMEMBER: "Recuérdame",
    _LOGIN: "INICIAR SESIÓN",
    _SIGNUP: "Registrarse",
    _FORGOTPASSWORD: "¿Olvidaste tu contraseña?",
    _NOACCOUNT: "¿No tienes una cuenta?",
    _ERROR_EMAIL: "¡Parece que no estás registrado! Inténtalo de nuevo.",
    _ERROR_PASSWORD: "Contraseña incorrecta",
    _SUCCESS: "Inicio de sesión exitoso",
  },
  _REGISTER: {
    _REGISTER: "Registrar cuenta",
    _NAME: "Nombre de usuario",
    _EMAIL: "Tu correo electrónico",
    _PASSWORD: "Tu contraseña",
    _CONFIRMPASSWORD: "Confirmar contraseña",
    _WHATSAPP: "WhatsApp",
    _TELEGRAM: "Telegram (opcional)",
    _HAVEACCOUNT: "Ya tengo una cuenta",
    _ERROR_REFERAL: "Código de referido incorrecto",
    _ERROR_EMAIL: "Este correo ya existe",
    _SUCCESS: "Registro exitoso",
  },
  _SIDEBAR: {
    _PAGES: "Páginas",
    _OPEN: "Abrir barra lateral",
    _DASHBOARD: "Inicio",
    _NETWORK: "Red",
    _FINANCE: "Billetera",
    _PACKAGES: "Planes",
    _SIGNOUT: "Cerrar sesión",
    _LANGUAGE: "Idioma",
    _PROFILE: "Perfil",
  },
  _DASHBOARD: {
    _TITLE: "Saldo",
    _TITLE2: "Depósito",
    _TITLE3: "Ganancias totales",
    _TITLE4: "Ganancias Unilevel",
    _TITLE5: "Bono de rango",
    _TITLE6: "Plan",
    _TOTAL: "Total",
    _TABLE3: "Lista de paquetes",
    _START: "Inicio",
    _FINISH: "Final",
    _STATUS: "Estado",
    _STATUS_ACTIVE: "Activo",
    _STATUS_INACTIVE: "Inactivo",
    _CURRENT: "Actual",
    _UPGRADE: "Mejorar",
    _COPYMESSAGE: "Copia exitosa",
    _NOTIFICATION1: "Nuevo depósito",
    _NOTIFICATION2: "Ganancia de Cryptotradex",
    _NOTIFICATION3: "Ganancia Unilevel",
    _NOTIFICATION4: "Rango infinito",
    _NOTIFICATION5: "Compra de paquete",
    _NOTIFICATION7: "Bono de Indicación Directa Recurrente",
    _PREV: "Anterior",
    _NEXT: "Siguiente",
    _TABLE4_A: "Operación",
    _TABLE4_B: "Ganancia",
    _TABLE4_C: "ROI",
    _TABLE4_D: "Fecha",
    _SHARED: "Comparte tu enlace de referido",
  },
  _FINANCE: {
    _DEPOSIT: "Depósito",
    _QR: "Escanea el siguiente código QR o copia la dirección",
    _NETWORK: "Red",
    _INSTRUCTIONS: "Instrucciones para enviar TRON (TRX)",
    _STEP1: "Asegúrate de que la red seleccionada sea TRON (TRC20).",
    _STEP2:
      "Envía la cantidad de TRON (TRX) a la siguiente dirección de billetera.",
    _COPY: "Copiar",
    _DISCLAIMER:
      "Descargo de responsabilidad: No somos responsables de transacciones realizadas fuera de la red TRON (TRC20) o por el envío de criptomonedas que no sean TRON (TRX). Cualquier pérdida de fondos debido a estos errores será responsabilidad única del remitente.",
    _TABLE1: "Historial de depósitos",
    _TABLE1_A: "Orden",
    _TABLE1_B: "Monto",
    _TABLE1_C: "Fecha",
    _TABLE1_D: "Estado",
    _SUCCESS: "El paquete se añadió exitosamente",
    _COPYMESSAGE: "Dirección de billetera copiada exitosamente",
  },
  _PACKAGES: {
    _ATTENTION:
      "Atención: Tienes una cuenta corporativa. Al comprar un paquete, reiniciarás la cuenta y todos sus beneficios.",
    _PACKAGES: "Selecciona el mejor plan para ti",
    _SUBTITLE: "Tienes 8 opciones",
    _BALANCE: "Saldo del depósito",
    _PACKAGE: "Paquete ",
    _PACKAGE_1: "Ganaras  hasta el nivel ",
    _PACKAGE_2: "Gana hasta 3X",
    _PACKAGE_3: "ROI diario",
    _BUTTON1: "Completado",
    _BUTTON2: "Comprar",
    _CALCULATE: "Calcular plan",
    _RECHARGE: "No tienes suficiente dinero, recarga tu billetera",
    _PACKAGEMESSAGE: "Debes comprar un paquete superior",
    _SUCCESS: "El paquete se añadió exitosamente",
    _UPDATE: "Precio de actualización de paquete",
  },
  _NETWORK: {
    _TODAY: "Hoy",
    _AVAILABLE: "Disponible",
    _WITHDRAW: "Total retirado",
    _SEARCHLABEL: "Búsqueda por correo",
    _SEARCH: "Buscar",
    _NETWORK: "Red",
    _DIRECTS: "Directos",
    _SUCCESS: "Búsqueda exitosa",
    _ERROR: "No es parte de tu árbol.",
    _LEVEL: "Nivel",
    _USERS: "Usuarios",
    _AMOUNT: "Monto",
    _USERNAME: "Nombre de usuario",
    _EMAIL: "Correo electrónico",
    _WHATSAPP: "WhatsApp",
    _PACKAGE: "Paquete",
  },
  _PROFILE: {
    _TITLE: "Perfil",
    _NAME: "Tu nombre",
    _EMAIL: "Tu correo",
    _WHATSAPP: "WhatsApp",
    _CURRENTPASSWORD: "Contraseña actual",
    _PASSWORD: "Nueva contraseña",
    _CONFIRMPASSWORD: "Confirmar nueva contraseña",
    _WALLET: "Dirección de retiro",
    _ACTION: "Editar",
    _TIPS: "Consejos",
    _TIPS_1: "El nombre debe ser legible y no exceder los 20 caracteres.",
    _TIPS_2:
      "La contraseña debe incluir números, letras y símbolos; cuanto más compleja, mejor.",
    _TIPS_3:
      "El correo no puede cambiarse. Si necesitas hacer un cambio, por favor escribe a soporte.",
    _TIPS_4:
      "La dirección de retiro solo puede modificarse con un autenticador de 2 factores, que debe estar habilitado previamente.",
    _ERROR_PASSWORD: "Error en la contraseña anterior",
    _SUCCESS: "Perfil actualizado exitosamente",
    _MIN: "Retiro mínimo",
    _UPDATE: "Actualizar perfil",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "¿Olvidaste tu contraseña?",
    _EMAIL: "Tu correo",
    _BUTTON: "Enviar correo",
    _MESSAGE: "Tu contraseña fue enviada a tu correo",
    _ERROR_EMAIL: "Este correo no está registrado",
  },
  _GLOBALV: {
    _TITLE: "GV",
    _CURRENT: "Rango actual",
    _NEXT: "Próximo rango",
    _SILVER: "Plata",
    _GOLD: "Oro",
    _RUBY: "Rubí",
    _EMERALD: "Esmeralda",
    _DIAMOND: "Diamante",
    _DIAMONDB: "Diamante Azul",
    _DIAMONDBB: "Diamante Negro",
    _NONE: "Ninguno",
  },
  _AUTH: {
    _TITLEDEFAULT: "Cambiar billetera de retiro",
    _TITLE: "Paso 1",
    _TITLE2: "Paso 2",
    _TITLE3: "Paso 3",
    _STEP1: "Descarga e instala el autenticador en tu dispositivo",
    _STEP2: "Escanea el código para guardar",
    _FORMEMAIL: "Verificación por correo",
    _FORMHELPEMAIL: "Ingresa el código de verificación de 6 dígitos enviado a",
    _FORMHELPAUTH:
      "Ingresa el código de 6 dígitos generado por la aplicación Authenticator",
    _FORM1: "Pin por Authenticator",
    _FORM2: "Dirección de retiro",
    _FORMMIN: "La dirección de retiro debe tener 34 caracteres",
    _FORMCODESEND: "Código enviado",
    _VERIFF: "Verificar",
    _ACTION: "Comprobar y guardar",
    _ERROR: "Error, inténtalo de nuevo.",
    _SUCCESS: "Cambio exitoso",
    _RETRYOTP: "Error al enviar el código, inténtalo de nuevo.",
    _SENDOTP: "Código enviado exitosamente",
    _SUCCESSOTP: "OTP verificado satisfactoriamente",
    _SUCCESSAUTH2F: "Auth2fa verificado satisfactoriamente",
    _ERRORCODE: "Código inválido, inténtalo de nuevo",
  },
  _COUNTDOWN: {
    _TITLE: "Próximo corte:",
    _DAYS: "Días",
    _HOURS: "Horas",
    _MINUTES: "Minutos",
    _SECONDS: "Segundos",
  },
  _WITHDRAWAL: {
    _TITLE: "Transacciones de retiro",
  },
  _SYSTEM: {
    _SYSTEM: "Sistema",
    _GLOBAL: "Global",
    _INVESTMENT: "Retirar inversión",
    _INVESTMENTTEXT:
      "Recuerda que tendrás una penalización del 25% de tu inversión",
    _ACCELERATE: "Acelerar",
    _REPORT: "Reporte de Inversión Cryptotradex",
    _PARNERT: "Informacion de patrocinador",
  },
  _WITHDRAWINVESTMENT: {
    _TITLE: "Retirar Inversión",
    _MESSAGE:
      "Retirar tu capital ahora implica un fee operativo del 25% y se procesará en 24 horas. Si lo haces, detendrás su potencial de crecimiento inmediato. ¡Decide con visión a largo plazo!",
    _BUTTON: "Retirar",
    _BUTTONWAIT: "Retiro en proceso",
  },
};
