export const th = {
  _LOGIN: {
    _EMAIL: "อีเมล",
    _PASSWORD: "รหัสผ่าน",
    _REMEMBER: "จำฉันไว้",
    _LOGIN: "เข้าสู่ระบบ",
    _SIGNUP: "สมัครสมาชิก",
    _FORGOTPASSWORD: "ลืมรหัสผ่าน?",
    _NOACCOUNT: "ยังไม่มีบัญชี?",
    _ERROR_EMAIL: "ดูเหมือนว่าคุณยังไม่ได้ลงทะเบียน! ลองอีกครั้ง.",
    _ERROR_PASSWORD: "รหัสผ่านไม่ถูกต้อง",
    _SUCCESS: "เข้าสู่ระบบสำเร็จ",
  },
  _REGISTER: {
    _REGISTER: "สมัครบัญชี",
    _NAME: "ชื่อผู้ใช้",
    _EMAIL: "อีเมลของคุณ",
    _PASSWORD: "รหัสผ่านของคุณ",
    _CONFIRMPASSWORD: "ยืนยันรหัสผ่าน",
    _WHATSAPP: "WhatsApp",
    _TELEGRAM: "Telegram (เลือกได้)",
    _HAVEACCOUNT: "ฉันมีบัญชีแล้ว",
    _ERROR_REFERAL: "รหัสแนะนำไม่ถูกต้อง",
    _ERROR_EMAIL: "อีเมลนี้มีอยู่แล้ว",
    _SUCCESS: "สมัครสมาชิกสำเร็จ",
  },
  _SIDEBAR: {
    _PAGES: "หน้า",
    _OPEN: "เปิดแถบด้านข้าง",
    _DASHBOARD: "หน้าหลัก",
    _NETWORK: "เครือข่าย",
    _FINANCE: "กระเป๋าเงิน",
    _PACKAGES: "แผนการ",
    _SIGNOUT: "ออกจากระบบ",
    _LANGUAGE: "ภาษา",
    _PROFILE: "โปรไฟล์",
  },
  _DASHBOARD: {
    _TITLE: "ยอดคงเหลือ",
    _TITLE2: "ฝากเงิน",
    _TITLE3: "รายได้ทั้งหมด",
    _TITLE4: "รายได้ Unilevel",
    _TITLE5: "โบนัสระดับ",
    _TITLE6: "แผนการ",
    _TOTAL: "รวม",
    _TABLE3: "รายการแพ็กเกจ",
    _START: "เริ่มต้น",
    _FINISH: "สิ้นสุด",
    _STATUS: "สถานะ",
    _STATUS_ACTIVE: "ใช้งาน",
    _STATUS_INACTIVE: "ไม่ใช้งาน",
    _CURRENT: "ปัจจุบัน",
    _UPGRADE: "อัปเกรด",
    _COPYMESSAGE: "คัดลอกสำเร็จ",
    _NOTIFICATION1: "ฝากเงินใหม่",
    _NOTIFICATION2: "กำไรจาก Cryptotradex",
    _NOTIFICATION3: "กำไรจาก Unilevel",
    _NOTIFICATION4: "ขอบเขตไม่จำกัด",
    _NOTIFICATION5: "ซื้อแพ็กเกจ",
    _NOTIFICATION7: "โบนัสการแนะนำตรงแบบเกิดซ้ำ",
    _PREV: "ก่อนหน้า",
    _NEXT: "ถัดไป",
    _TABLE4_A: "การดำเนินการ",
    _TABLE4_B: "กำไร",
    _TABLE4_C: "ROI",
    _TABLE4_D: "วันที่",
    _SHARED: "แชร์ลิงก์แนะนำของคุณ",
  },
  _FINANCE: {
    _DEPOSIT: "ฝากเงิน",
    _QR: "สแกน QR ด้านล่างหรือคัดลอกที่อยู่",
    _NETWORK: "เครือข่าย",
    _INSTRUCTIONS: "คำแนะนำในการส่ง TRON (TRX)",
    _STEP1: "ตรวจสอบให้แน่ใจว่าเครือข่ายที่เลือกคือ TRON (TRC20)",
    _STEP2: "ส่งจำนวน TRON (TRX) ไปยังที่อยู่กระเป๋าเงินต่อไปนี้",
    _COPY: "คัดลอก",
    _DISCLAIMER:
      "คำปฏิเสธ: เราจะไม่รับผิดชอบต่อธุรกรรมที่ดำเนินการนอกเครือข่าย TRON (TRC20) หรือการส่งสกุลเงินดิจิทัลอื่นนอกเหนือจาก TRON (TRX) การสูญเสียเงินจากข้อผิดพลาดเหล่านี้จะเป็นความรับผิดชอบของผู้ส่งเท่านั้น",
    _TABLE1: "ประวัติการฝากเงิน",
    _TABLE1_A: "คำสั่งซื้อ",
    _TABLE1_B: "จำนวนเงิน",
    _TABLE1_C: "วันที่",
    _TABLE1_D: "สถานะ",
    _SUCCESS: "เพิ่มแพ็กเกจสำเร็จ",
    _COPYMESSAGE: "คัดลอกที่อยู่กระเป๋าเงินสำเร็จ",
  },
  _PACKAGES: {
    _ATTENTION:
      "หมายเหตุ: คุณมีบัญชีองค์กร เมื่อซื้อแพ็กเกจบัญชีของคุณจะถูกรีเซ็ตและสิทธิประโยชน์ทั้งหมดจะหายไป",
    _PACKAGES: "เลือกแผนที่ดีที่สุดสำหรับคุณ",
    _SUBTITLE: "คุณมี 8 ตัวเลือก",
    _BALANCE: "ยอดเงินฝาก",
    _PACKAGE: "แพ็กเกจ",
    _PACKAGE_1: "คุณจะได้รับรายได้จากระดับ",
    _PACKAGE_2: "รับสูงสุด 3 เท่า",
    _PACKAGE_3: "ROI รายวัน",
    _BUTTON1: "เต็มแล้ว",
    _BUTTON2: "ซื้อ",
    _CALCULATE: "คำนวณแผนการ",
    _RECHARGE: "คุณไม่มีเงินเพียงพอ กรุณาเติมเงินในกระเป๋าเงินของคุณ",
    _PACKAGEMESSAGE: "คุณต้องซื้อแพ็กเกจที่สูงกว่า",
    _SUCCESS: "เพิ่มแพ็กเกจสำเร็จ",
    _UPDATE: "ราคาอัปเดตแพ็กเกจ",
  },
  _NETWORK: {
    _TODAY: "วันนี้",
    _AVAILABLE: "ใช้ได้",
    _WITHDRAW: "ถอนทั้งหมด",
    _SEARCHLABEL: "ค้นหาอีเมล",
    _SEARCH: "ค้นหา",
    _NETWORK: "เครือข่าย",
    _DIRECTS: "อ้างอิงโดยตรง",
    _SUCCESS: "ค้นหาสำเร็จ",
    _ERROR: "ไม่ใช่ส่วนหนึ่งของต้นไม้ไบนารีของคุณ",
    _LEVEL: "ระดับ",
    _USERS: "ผู้ใช้",
    _AMOUNT: "จำนวน",
    _USERNAME: "ชื่อผู้ใช้",
    _EMAIL: "อีเมล",
    _WHATSAPP: "WhatsApp",
    _PACKAGE: "แพ็กเกจ",
  },
  _PROFILE: {
    _TITLE: "โปรไฟล์",
    _NAME: "ชื่อของคุณ",
    _EMAIL: "อีเมลของคุณ",
    _WHATSAPP: "WhatsApp",
    _CURRENTPASSWORD: "รหัสผ่านปัจจุบัน",
    _PASSWORD: "รหัสผ่านใหม่",
    _CONFIRMPASSWORD: "ยืนยันรหัสผ่านใหม่",
    _WALLET: "ที่อยู่ถอนเงิน",
    _ACTION: "แก้ไข",
    _TIPS: "เคล็ดลับ",
    _TIPS_1: "ชื่อจะต้องอ่านง่ายและไม่เกิน 20 ตัวอักษร",
    _TIPS_2:
      "รหัสผ่านจะต้องประกอบด้วยตัวเลข ตัวอักษร และสัญลักษณ์ ยิ่งซับซ้อนมากเท่าไหร่ยิ่งดี",
    _TIPS_3:
      "ไม่สามารถเปลี่ยนอีเมลได้ หากต้องการเปลี่ยน โปรดติดต่อฝ่ายสนับสนุน",
    _TIPS_4:
      "ที่อยู่ถอนเงินสามารถแก้ไขได้ด้วยการยืนยัน 2 ขั้นตอนเท่านั้น และต้องเปิดใช้งานล่วงหน้า",
    _ERROR_PASSWORD: "รหัสผ่านก่อนหน้านี้ไม่ถูกต้อง",
    _SUCCESS: "อัปเดตโปรไฟล์สำเร็จ",
    _MIN: "จำนวนขั้นต่ำในการถอน",
    _UPDATE: "อัปเดตโปรไฟล์",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "ลืมรหัสผ่าน?",
    _EMAIL: "อีเมลของคุณ",
    _BUTTON: "ส่งอีเมล",
    _MESSAGE: "รหัสผ่านของคุณถูกส่งไปยังอีเมลของคุณแล้ว",
    _ERROR_EMAIL: "อีเมลนี้ยังไม่ได้ลงทะเบียน",
  },
  _GLOBALV: {
    _TITLE: "GV",
    _CURRENT: "ระดับปัจจุบัน",
    _NEXT: "ระดับถัดไป",
    _SILVER: "เงิน",
    _GOLD: "ทอง",
    _RUBY: "ทับทิม",
    _EMERALD: "มรกต",
    _DIAMOND: "เพชร",
    _DIAMONDB: "เพชรน้ำเงิน",
    _DIAMONDBB: "เพชรดำ",
    _NONE: "ไม่มี",
  },
  _AUTH: {
    _TITLEDEFAULT: "เปลี่ยนกระเป๋าเงินสำหรับถอน",
    _TITLE: "ขั้นตอนที่ 1",
    _TITLE2: "ขั้นตอนที่ 2",
    _TITLE3: "ขั้นตอนที่ 3",
    _STEP1: "ดาวน์โหลดและติดตั้งแอปยืนยันตัวตนบนอุปกรณ์ของคุณ",
    _STEP2: "สแกนโค้ดเพื่อบันทึก",
    _FORMEMAIL: "การยืนยันอีเมล",
    _FORMHELPEMAIL: "ป้อนรหัสยืนยัน 6 หลักที่ส่งไปยัง",
    _FORMHELPAUTH: "ป้อนรหัส 6 หลักที่สร้างโดยแอปยืนยันตัวตน",
    _FORM1: "รหัสโดยแอปยืนยันตัวตน",
    _FORM2: "ที่อยู่ถอนเงิน",
    _FORMMIN: "ที่อยู่ถอนเงินจะต้องยาว 34 ตัวอักษร",
    _FORMCODESEND: "ส่งรหัสแล้ว",
    _VERIFF: "ตรวจสอบ",
    _ACTION: "ตรวจสอบและบันทึก",
    _ERROR: "เกิดข้อผิดพลาด โปรดลองอีกครั้ง",
    _SUCCESS: "เปลี่ยนสำเร็จ",
    _RETRYOTP: "เกิดข้อผิดพลาดในการส่งรหัส โปรดลองอีกครั้ง",
    _SENDOTP: "ส่งรหัสสำเร็จ",
    _SUCCESSOTP: "ยืนยัน OTP สำเร็จ",
    _SUCCESSAUTH2F: "ยืนยัน Auth2fa สำเร็จ",
    _ERRORCODE: "รหัสไม่ถูกต้อง ลองอีกครั้ง",
  },
  _COUNTDOWN: {
    _TITLE: "การตัดยอดถัดไป:",
    _DAYS: "วัน",
    _HOURS: "ชั่วโมง",
    _MINUTES: "นาที",
    _SECONDS: "วินาที",
  },
  _WITHDRAWAL: {
    _TITLE: "ธุรกรรมการถอนเงิน",
  },
  _SYSTEM: {
    _SYSTEM: "ระบบ",
    _GLOBAL: "ทั่วโลก",
    _INVESTMENT: "ถอนการลงทุน",
    _INVESTMENTTEXT: "โปรดจำไว้ว่าคุณจะถูกหักค่าปรับ 25% ของการลงทุนของคุณ",
    _ACCELERATE: "เร่งความเร็ว",
    _REPORT: "รายงานการลงทุน Cryptotradex",
    _PARNERT: "ข้อมูลผู้สนับสนุน",
  },
  _WITHDRAWINVESTMENT: {
    _TITLE: "ถอนการลงทุน",
    _MESSAGE:
      "การถอนทุนของคุณในตอนนี้จะมีค่าธรรมเนียมการดำเนินการ 25% และจะดำเนินการภายใน 24 ชั่วโมง หากคุณทำเช่นนั้น ศักยภาพการเติบโตของมันจะหยุดทันที ตัดสินใจด้วยวิสัยทัศน์ระยะยาว!",
    _BUTTON: "ถอนเงิน",
    _BUTTONWAIT: "กำลังดำเนินการถอนเงิน",
  },
};
