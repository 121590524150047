export const eng = {
  _LOGIN: {
    _EMAIL: "Email",
    _PASSWORD: "Password",
    _REMEMBER: "Remember me",
    _LOGIN: "LOGIN",
    _SIGNUP: "Sign up",
    _FORGOTPASSWORD: "Forgot your password?",
    _NOACCOUNT: "Don't have an account?",
    _ERROR_EMAIL: "Apparently you are not registered! Try again.",
    _ERROR_PASSWORD: "Incorrect password",
    _SUCCESS: "Login Success",
  },
  _REGISTER: {
    _REGISTER: "Register Account",
    _NAME: "Username",
    _EMAIL: "Your email",
    _PASSWORD: "Your password",
    _CONFIRMPASSWORD: "Confirm Password",
    _WHATSAPP: "WhatsApp",
    _TELEGRAM: "Telegram (optional)",
    _HAVEACCOUNT: "I have an account",
    _ERROR_REFERAL: "Wrong referral code",
    _ERROR_EMAIL: "This email already exists",
    _SUCCESS: "Registration Success",
  },
  _SIDEBAR: {
    _PAGES: "Pages",
    _OPEN: "Open sidebar",
    _DASHBOARD: "Home",
    _NETWORK: "Network",
    _FINANCE: "Wallet",
    _PACKAGES: "Plans",
    _SIGNOUT: "Sign Out",
    _LANGUAGE: "Language",
    _PROFILE: "Profile",
  },
  _DASHBOARD: {
    _TITLE: "Balance",
    _TITLE2: "Deposit",
    _TITLE3: "Total Earnings",
    _TITLE4: "Unilevel Earnings",
    _TITLE5: "Rank Bonus",
    _TITLE6: "Plan",
    _TOTAL: "Total",
    _TABLE3: "Packages List",
    _START: "Start",
    _FINISH: "Finish",
    _STATUS: "Status",
    _STATUS_ACTIVE: "Active",
    _STATUS_INACTIVE: "Inactive",
    _CURRENT: "Current",
    _UPGRADE: "Upgrade",
    _COPYMESSAGE: "Copy successful",
    _NOTIFICATION1: "New Deposit",
    _NOTIFICATION2: "Cryptotradex Profit",
    _NOTIFICATION3: "Unilevel Profit",
    _NOTIFICATION4: "Range Infinite",
    _NOTIFICATION5: "Purchase Package",
    _NOTIFICATION7: "Recurring Direct Referral Bonus",
    _PREV: "Previous",
    _NEXT: "Next",
    _TABLE4_A: "Operation",
    _TABLE4_B: "Profit",
    _TABLE4_C: "ROI",
    _TABLE4_D: "Date",
    _SHARED: "Share your referral link",
  },
  _FINANCE: {
    _DEPOSIT: "Deposit",
    _QR: "Scan the following QR or copy the address",
    _NETWORK: "Network",
    _INSTRUCTIONS: "Instructions for Sending TRON (TRX)",
    _STEP1: "Make sure the selected network is TRON (TRC20).",
    _STEP2: "Send the amount of TRON (TRX) to the following wallet address.",
    _COPY: "Copy",
    _DISCLAIMER:
      "Disclaimer: We are not responsible for transactions made outside the TRON (TRC20) network or for sending cryptocurrencies other than TRON (TRX). Any loss of funds due to these errors will be the sole responsibility of the sender.",
    _TABLE1: "Deposit History",
    _TABLE1_A: "Order",
    _TABLE1_B: "Amount",
    _TABLE1_C: "Date",
    _TABLE1_D: "Status",
    _SUCCESS: "The package was added successfully",
    _COPYMESSAGE: "Wallet address copied successfully",
  },
  _PACKAGES: {
    _ATTENTION:
      "Attention: You have a corporate account. By purchasing a package, you will restart the account and all its benefits.",
    _PACKAGES: "Select the best plan for you",
    _SUBTITLE: "You have 8 options",
    _BALANCE: "Balance Deposit",
    _PACKAGE: "Package ",
    _PACKAGE_1: "You will earn up to level ",
    _PACKAGE_2: "Earn up to 3X",
    _PACKAGE_3: "Daily ROI",
    _BUTTON1: "Filled",
    _BUTTON2: "Buy",
    _CALCULATE: "Calculate Plan",
    _RECHARGE: "You don't have enough money, recharge your wallet",
    _PACKAGEMESSAGE: "You must purchase a higher package",
    _SUCCESS: "The package was added successfully",
    _UPDATE: "Package upgrade price",
  },
  _NETWORK: {
    _TODAY: "Today",
    _AVAILABLE: "Available",
    _WITHDRAW: "Total Withdrawn",
    _SEARCHLABEL: "Email Search",
    _SEARCH: "Search",
    _NETWORK: "Network",
    _DIRECTS: "Directs",
    _SUCCESS: "Search Successful",
    _ERROR: "Not part of your tree.",
    _LEVEL: "Level",
    _USERS: "Users",
    _AMOUNT: "Amount",
    _USERNAME: "Username",
    _EMAIL: "Email",
    _WHATSAPP: "WhatsApp",
    _PACKAGE: "Package",
  },
  _PROFILE: {
    _TITLE: "Profile",
    _NAME: "Your name",
    _EMAIL: "Your email",
    _WHATSAPP: "WhatsApp",
    _CURRENTPASSWORD: "Current password",
    _PASSWORD: "New password",
    _CONFIRMPASSWORD: "Confirm new password",
    _WALLET: "Withdrawal address",
    _ACTION: "Edit",
    _TIPS: "Tips",
    _TIPS_1: "The name must be legible and not exceed 20 characters.",
    _TIPS_2:
      "The password must include numbers, letters, and symbols; the more complex, the better.",
    _TIPS_3:
      "The email cannot be changed. If you need to make a change, please write to support.",
    _TIPS_4:
      "The withdrawal address can only be modified with a 2-factor authenticator, which must be enabled previously.",
    _ERROR_PASSWORD: "Previous password error",
    _SUCCESS: "Profile updated successfully",
    _MIN: "Minimum withdrawal",
    _UPDATE: "Update Profile",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "Forgot your password?",
    _EMAIL: "Your email",
    _BUTTON: "Send email",
    _MESSAGE: "Your password was sent to your email",
    _ERROR_EMAIL: "This email is not registered",
  },
  _GLOBALV: {
    _TITLE: "GV",
    _CURRENT: "Current Rank",
    _NEXT: "Next Rank",
    _SILVER: "Silver",
    _GOLD: "Gold",
    _RUBY: "Ruby",
    _EMERALD: "Emerald",
    _DIAMOND: "Diamond",
    _DIAMONDB: "Diamond Blue",
    _DIAMONDBB: "Diamond Black",
    _NONE: "None",
  },
  _AUTH: {
    _TITLEDEFAULT: "Change withdrawal wallet",
    _TITLE: "Step 1",
    _TITLE2: "Step 2",
    _TITLE3: "Step 3",
    _STEP1: "Download and install the authenticator on your device",
    _STEP2: "Scan the code to save",
    _FORMEMAIL: "Email Verification",
    _FORMHELPEMAIL: "Enter the 6-digit verification code sent to",
    _FORMHELPAUTH: "Enter the 6-digit code generated by the Authenticator App",
    _FORM1: "Pin by Authenticator",
    _FORM2: "Withdrawal address",
    _FORMMIN: "The withdrawal address must be 34 characters long",
    _FORMCODESEND: "Code Send",
    _VERIFF: "Veriff",
    _ACTION: "Check and Save",
    _ERROR: "Error, try again.",
    _SUCCESS: "Change successful",
    _RETRYOTP: "Error sending the code, try again.",
    _SENDOTP: "Code sent successfully",
    _SUCCESSOTP: "OTP Satisfactorily Verified",
    _SUCCESSAUTH2F: "Auth2fa Satisfactorily Verified",
    _ERRORCODE: "Invalid code try again",
  },
  _COUNTDOWN: {
    _TITLE: "Next cut:",
    _DAYS: "Days",
    _HOURS: "Hours",
    _MINUTES: "Minutes",
    _SECONDS: "Seconds",
  },
  _WITHDRAWAL: {
    _TITLE: "Withdrawal Transactions",
  },
  _SYSTEM: {
    _SYSTEM: "System",
    _GLOBAL: "Global",
    _INVESTMENT: "Withdraw investment",
    _INVESTMENTTEXT:
      "Remember that you will have a penalty of 25% of your investment",
    _ACCELERATE: "Accelerate",
    _REPORT: "Cryptotradex Investment Report",
    _PARNERT: "Sponsor information",
  },
  _WITHDRAWINVESTMENT: {
    _TITLE: "Withdraw Investment",
    _MESSAGE:
      "Withdrawing your capital now incurs a 25% operational fee and will be processed within 24 hours. If you do so, you will immediately stop its growth potential. Make your decision with a long-term vision!",
    _BUTTON: "Withdraw",
    _BUTTONWAIT: "Withdrawal in process",
  },
};
