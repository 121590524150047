import fetchIntercept from "fetch-intercept";

export const AuthInterceptor = () => {
  fetchIntercept.register({
    request: async function (url, config) {
      return [url, config];
    },

    requestError: function (error) {
      // Called when an error occured during another 'request' interceptor call
      return Promise.reject(error);
    },

    response: function (response) {
      // Modify the reponse object
      if (response.status === 400) {
        response.json().then((data) => {
          if (data.status === false && data.code === 50008) {
            console.log("Token Vencido");
            window.localStorage.removeItem("user");
            window.localStorage.removeItem("token");
            window.localStorage.removeItem("accountAddress");
            window.location.replace("/");
          }
        });
      }
      return response;
    },

    responseError: function (error) {
      // Handle an fetch error
      return Promise.reject(error);
    },
  });
};
