export const por = {
  _LOGIN: {
    _EMAIL: "Email",
    _PASSWORD: "Senha",
    _REMEMBER: "Lembrar-me",
    _LOGIN: "ENTRAR",
    _SIGNUP: "Cadastrar-se",
    _FORGOTPASSWORD: "Esqueceu sua senha?",
    _NOACCOUNT: "Não tem uma conta?",
    _ERROR_EMAIL: "Parece que você não está registrado! Tente novamente.",
    _ERROR_PASSWORD: "Senha incorreta",
    _SUCCESS: "Login realizado com sucesso",
  },
  _REGISTER: {
    _REGISTER: "Registrar Conta",
    _NAME: "Nome de usuário",
    _EMAIL: "Seu email",
    _PASSWORD: "Sua senha",
    _CONFIRMPASSWORD: "Confirmar senha",
    _WHATSAPP: "WhatsApp",
    _TELEGRAM: "Telegram (opcional)",
    _HAVEACCOUNT: "Já tenho uma conta",
    _ERROR_REFERAL: "Código de referência incorreto",
    _ERROR_EMAIL: "Este email já existe",
    _SUCCESS: "Registro realizado com sucesso",
  },
  _SIDEBAR: {
    _PAGES: "Páginas",
    _OPEN: "Abrir barra lateral",
    _DASHBOARD: "Início",
    _NETWORK: "Rede",
    _FINANCE: "Carteira",
    _PACKAGES: "Planos",
    _SIGNOUT: "Sair",
    _LANGUAGE: "Idioma",
    _PROFILE: "Perfil",
  },
  _DASHBOARD: {
    _TITLE: "Saldo",
    _TITLE2: "Depósito",
    _TITLE3: "Ganhos Totais",
    _TITLE4: "Ganhos Unilevel",
    _TITLE5: "Bônus de Ranking",
    _TITLE6: "Plano",
    _TOTAL: "Total",
    _TABLE3: "Lista de Pacotes",
    _START: "Início",
    _FINISH: "Fim",
    _STATUS: "Status",
    _STATUS_ACTIVE: "Ativo",
    _STATUS_INACTIVE: "Inativo",
    _CURRENT: "Atual",
    _UPGRADE: "Atualizar",
    _COPYMESSAGE: "Copiado com sucesso",
    _NOTIFICATION1: "Novo Depósito",
    _NOTIFICATION2: "Lucro do Cryptotradex",
    _NOTIFICATION3: "Lucro Unilevel",
    _NOTIFICATION4: "Alcance Infinito",
    _NOTIFICATION5: "Comprar Pacote",
    _NOTIFICATION7: "Bônus de Indicação Direta Recorrente",
    _PREV: "Anterior",
    _NEXT: "Próximo",
    _TABLE4_A: "Operação",
    _TABLE4_B: "Lucro",
    _TABLE4_C: "ROI",
    _TABLE4_D: "Data",
    _SHARED: "Compartilhe seu link de referência",
  },
  _FINANCE: {
    _DEPOSIT: "Depósito",
    _QR: "Escaneie o QR code abaixo ou copie o endereço",
    _NETWORK: "Rede",
    _INSTRUCTIONS: "Instruções para enviar TRON (TRX)",
    _STEP1: "Certifique-se de que a rede selecionada seja TRON (TRC20).",
    _STEP2:
      "Envie a quantidade de TRON (TRX) para o seguinte endereço de carteira.",
    _COPY: "Copiar",
    _DISCLAIMER:
      "Aviso: Não somos responsáveis por transações feitas fora da rede TRON (TRC20) ou por enviar criptomoedas diferentes de TRON (TRX). Qualquer perda de fundos devido a esses erros será de total responsabilidade do remetente.",
    _TABLE1: "Histórico de Depósitos",
    _TABLE1_A: "Ordem",
    _TABLE1_B: "Valor",
    _TABLE1_C: "Data",
    _TABLE1_D: "Status",
    _SUCCESS: "O pacote foi adicionado com sucesso",
    _COPYMESSAGE: "Endereço da carteira copiado com sucesso",
  },
  _PACKAGES: {
    _ATTENTION:
      "Atenção: Você tem uma conta corporativa. Ao comprar um pacote, você reiniciará a conta e todos os seus benefícios.",
    _PACKAGES: "Selecione o melhor plano para você",
    _SUBTITLE: "Você tem 8 opções",
    _BALANCE: "Saldo de Depósito",
    _PACKAGE: "Pacote",
    _PACKAGE_1: "Você começará a ganhar a partir do nível",
    _PACKAGE_2: "Ganhe até 3X",
    _PACKAGE_3: "ROI Diário",
    _BUTTON1: "Cheio",
    _BUTTON2: "Comprar",
    _CALCULATE: "Calcular Plano",
    _RECHARGE: "Você não tem saldo suficiente, recarregue sua carteira",
    _PACKAGEMESSAGE: "Você deve comprar um pacote superior",
    _SUCCESS: "O pacote foi adicionado com sucesso",
    _UPDATE: "Preço de atualização de pacote",
  },
  _NETWORK: {
    _TODAY: "Hoje",
    _AVAILABLE: "Disponível",
    _WITHDRAW: "Total Sacado",
    _SEARCHLABEL: "Pesquisar por Email",
    _SEARCH: "Pesquisar",
    _NETWORK: "Rede",
    _DIRECTS: "Diretos",
    _SUCCESS: "Pesquisa realizada com sucesso",
    _ERROR: "Não faz parte da sua árvore binária.",
    _LEVEL: "Nível",
    _USERS: "Usuários",
    _AMOUNT: "Quantidade",
    _USERNAME: "Nome de Usuário",
    _EMAIL: "Email",
    _WHATSAPP: "WhatsApp",
    _PACKAGE: "Pacote",
  },
  _PROFILE: {
    _TITLE: "Perfil",
    _NAME: "Seu nome",
    _EMAIL: "Seu email",
    _WHATSAPP: "WhatsApp",
    _CURRENTPASSWORD: "Senha atual",
    _PASSWORD: "Nova senha",
    _CONFIRMPASSWORD: "Confirmar nova senha",
    _WALLET: "Endereço de retirada",
    _ACTION: "Editar",
    _TIPS: "Dicas",
    _TIPS_1: "O nome deve ser legível e não deve ultrapassar 20 caracteres.",
    _TIPS_2:
      "A senha deve incluir números, letras e símbolos; quanto mais complexa, melhor.",
    _TIPS_3:
      "O email não pode ser alterado. Se você precisar fazer uma mudança, por favor entre em contato com o suporte.",
    _TIPS_4:
      "O endereço de retirada só pode ser modificado com autenticação de dois fatores, que deve estar habilitada anteriormente.",
    _ERROR_PASSWORD: "Erro na senha anterior",
    _SUCCESS: "Perfil atualizado com sucesso",
    _MIN: "Saque mínimo",
    _UPDATE: "Atualizar Perfil",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "Esqueceu sua senha?",
    _EMAIL: "Seu email",
    _BUTTON: "Enviar email",
    _MESSAGE: "Sua senha foi enviada para seu email",
    _ERROR_EMAIL: "Este email não está registrado",
  },
  _GLOBALV: {
    _TITLE: "GV",
    _CURRENT: "Classificação Atual",
    _NEXT: "Próxima Classificação",
    _SILVER: "Prata",
    _GOLD: "Ouro",
    _RUBY: "Rubi",
    _EMERALD: "Esmeralda",
    _DIAMOND: "Diamante",
    _DIAMONDB: "Diamante Azul",
    _DIAMONDBB: "Diamante Negro",
    _NONE: "Nenhum",
  },
  _AUTH: {
    _TITLEDEFAULT: "Alterar carteira de retirada",
    _TITLE: "Passo 1",
    _TITLE2: "Passo 2",
    _TITLE3: "Passo 3",
    _STEP1: "Baixe e instale o autenticador em seu dispositivo",
    _STEP2: "Escaneie o código para salvar",
    _FORMEMAIL: "Verificação de Email",
    _FORMHELPEMAIL: "Insira o código de verificação de 6 dígitos enviado para",
    _FORMHELPAUTH:
      "Insira o código de 6 dígitos gerado pelo aplicativo Authenticator",
    _FORM1: "Código do Authenticator",
    _FORM2: "Endereço de retirada",
    _FORMMIN: "O endereço de retirada deve ter 34 caracteres",
    _FORMCODESEND: "Código enviado",
    _VERIFF: "Verificar",
    _ACTION: "Verificar e salvar",
    _ERROR: "Erro, tente novamente.",
    _SUCCESS: "Alteração realizada com sucesso",
    _RETRYOTP: "Erro ao enviar o código, tente novamente.",
    _SENDOTP: "Código enviado com sucesso",
    _SUCCESSOTP: "OTP verificado com sucesso",
    _SUCCESSAUTH2F: "Autenticação de dois fatores verificada com sucesso",
    _ERRORCODE: "Código inválido, tente novamente",
  },
  _COUNTDOWN: {
    _TITLE: "Próximo corte:",
    _DAYS: "Dias",
    _HOURS: "Horas",
    _MINUTES: "Minutos",
    _SECONDS: "Segundos",
  },
  _WITHDRAWAL: {
    _TITLE: "Transações de Retirada",
  },
  _SYSTEM: {
    _SYSTEM: "Sistema",
    _GLOBAL: "Global",
    _INVESTMENT: "Retirar investimento",
    _INVESTMENTTEXT:
      "Lembre-se de que haverá uma penalidade de 25% do seu investimento",
    _ACCELERATE: "Acelerar",
    _REPORT: "Relatório de Investimento Cryptotradex",
    _PARNERT: "Informação do patrocinador",
  },
  _WITHDRAWINVESTMENT: {
    _TITLE: "Retirar Investimento",
    _MESSAGE:
      "Retirar seu capital agora implica uma taxa operacional de 25% e será processado em até 24 horas. Se fizer isso, interromperá imediatamente o seu potencial de crescimento. Decida com uma visão de longo prazo!",
    _BUTTON: "Retirar",
    _BUTTONWAIT: "Retirada em processo",
  },
};
