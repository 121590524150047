import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../context/AppContext";
import { formatDistanceToNow, parseISO } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { Link, useNavigate } from 'react-router-dom';

export default function Withdrawal() {
  const { t } = useTranslation();
  const [wallet, setWallet] = useState();
  const [listDeposit, setListDeposit] = useState([]);
  const [listTotal, setListTotal] = useState(0);
  const { loading, updateLoading } = useContext(AppContext);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);


  useEffect(() => {
    getWithdrawalList();
  }, []);

  useEffect(() => {
    getWithdrawalList();
  }, [page, limit]);


  const getWithdrawalList = () => {
    updateLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND}/withdrawal?limit=40`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === false) {
          updateLoading(false);
        } else {
          setListDeposit(response?.data?.items);
          setListTotal(response?.data?.total)
          updateLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  }

  const formatDate = (dateString) => {
    const date = parseISO(dateString);
    return formatDistanceToNow(date, { locale: enUS, addSuffix: true });
  }

  function formatString(str) {
    if (str.length <= 8) {
      return str; // Devuelve la cadena original si es demasiado corta para ser formateada
    }
    const firstPart = str.slice(0, 4); // Obtiene los primeros 4 dígitos
    const lastPart = str.slice(-10); // Obtiene los últimos 4 dígitos
    return `${firstPart}....${lastPart}`; // Combina las partes con puntos en el medio
  }


  return (
    <div className="mx-auto lg:p-4 h-screen">
      <h2 className="text-lg font-semibold mb-4 text-white" >{t("_WITHDRAWAL._TITLE")} </h2>
      <div className=" overflow-x-auto w-full">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 rounded-lg ">
          <thead className="text-md text-white border-b-2">
            <tr>
              <th className="px-6 py-4 ">
                {t("_FINANCE._TABLE1_A")}
              </th>
              <th className="px-6 py-4 ">
                {t("_FINANCE._TABLE1_B")}
              </th>
              <th className="px-6 py-4">
                HASH TRX
              </th>
              <th className="px-6 py-4 ">
                {t("_FINANCE._TABLE1_C")}
              </th>
            </tr>
          </thead>
          <tbody>
            {listDeposit.map((deposit, index) => (
              deposit?.trxSmart && (
                <tr className="border-b-2" key={index} >
                  <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap text-white">
                    {parseInt(deposit.id) + parseInt(1000)}
                  </th>
                  <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap text-white">
                    {deposit.total_usd} USD
                  </th>
                  <td className="px-6 py-4 text-white">
                    <Link to={`https://tronscan.org/#/transaction/${deposit?.trxSmart}`} target={"_blank"} >{formatString(deposit?.trxSmart || "0000000000000000")}</Link>
                  </td>
                  <td className="px-6 py-4 text-white">
                    {formatDate(deposit.created)}
                  </td>
                </tr>
              )
            ))}
          </tbody>
        </table>
        <nav className="flex items-center justify-center flex-column flex-wrap md:flex-row pt-4" aria-label="Table navigation">
          <ul className="flex justify-center items-center h-8">
            {page > 1 && (
              <li>
                <button className="flex items-center justify-center px-3 h-12 border-2 rounded-lg text-white" onClick={() => setPage((prev) => Math.max(prev - 1, 1))}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                  </svg>
                  PREV
                </button>
              </li>
            )}
            <li>
              <button className={`flex items-center justify-center px-3 h-12 text-white mx-6`}>
                {page} / {Math.ceil(total / limit)}
              </button>
            </li>
            {page < Math.ceil(total / limit) && (
              <li>
                <button className="flex items-center justify-center px-3 h-12 border-2 rounded-lg text-white" onClick={() => setPage((prev) => Math.min(prev + 1, Math.ceil(total / limit)))}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                  </svg>
                  NEXT
                </button>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </div>
  );
}
