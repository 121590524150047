export const sl = {
  _LOGIN: {
    _EMAIL: "E-pošta",
    _PASSWORD: "Geslo",
    _REMEMBER: "Zapomni si me",
    _LOGIN: "PRIJAVA",
    _SIGNUP: "Registriraj se",
    _FORGOTPASSWORD: "Ste pozabili geslo?",
    _NOACCOUNT: "Nimate računa?",
    _ERROR_EMAIL: "Očitno niste registrirani! Poskusite znova.",
    _ERROR_PASSWORD: "Napačno geslo",
    _SUCCESS: "Uspešna prijava",
  },
  _REGISTER: {
    _REGISTER: "Registracija računa",
    _NAME: "Uporabniško ime",
    _EMAIL: "Vaša e-pošta",
    _PASSWORD: "Vaše geslo",
    _CONFIRMPASSWORD: "Potrdite geslo",
    _WHATSAPP: "WhatsApp",
    _TELEGRAM: "Telegram (neobvezno)",
    _HAVEACCOUNT: "Že imam račun",
    _ERROR_REFERAL: "Napačna referenčna koda",
    _ERROR_EMAIL: "Ta e-pošta že obstaja",
    _SUCCESS: "Uspešna registracija",
  },
  _SIDEBAR: {
    _PAGES: "Strani",
    _OPEN: "Odpri stransko vrstico",
    _DASHBOARD: "Domov",
    _NETWORK: "Mreža",
    _FINANCE: "Denarnica",
    _PACKAGES: "Načrti",
    _SIGNOUT: "Odjavi se",
    _LANGUAGE: "Jezik",
    _PROFILE: "Profil",
  },
  _DASHBOARD: {
    _TITLE: "Stanje",
    _TITLE2: "Depozit",
    _TITLE3: "Skupni dobički",
    _TITLE4: "Unilevel dobički",
    _TITLE5: "Bonusi po rangih",
    _TITLE6: "Načrt",
    _TOTAL: "Skupaj",
    _TABLE3: "Seznam paketov",
    _START: "Začetek",
    _FINISH: "Konec",
    _STATUS: "Status",
    _STATUS_ACTIVE: "Aktiven",
    _STATUS_INACTIVE: "Neaktiven",
    _CURRENT: "Trenutno",
    _UPGRADE: "Nadgradnja",
    _COPYMESSAGE: "Uspešno kopirano",
    _NOTIFICATION1: "Nov depozit",
    _NOTIFICATION2: "Cryptotradex dobiček",
    _NOTIFICATION3: "Unilevel dobiček",
    _NOTIFICATION4: "Obseg neskončen",
    _NOTIFICATION5: "Nakup paketa",
    _NOTIFICATION7: "Ponavljajoči neposredni referenčni bonus",
    _PREV: "Prejšnji",
    _NEXT: "Naslednji",
    _TABLE4_A: "Operacija",
    _TABLE4_B: "Dobiček",
    _TABLE4_C: "ROI",
    _TABLE4_D: "Datum",
    _SHARED: "Delite svojo referenčno povezavo",
  },
  _FINANCE: {
    _DEPOSIT: "Depozit",
    _QR: "Skenirajte naslednji QR ali kopirajte naslov",
    _NETWORK: "Mreža",
    _INSTRUCTIONS: "Navodila za pošiljanje TRON (TRX)",
    _STEP1: "Prepričajte se, da je izbrana mreža TRON (TRC20).",
    _STEP2: "Pošljite znesek TRON (TRX) na naslednji naslov denarnice.",
    _COPY: "Kopiraj",
    _DISCLAIMER:
      "Odzivnost: Nismo odgovorni za transakcije, opravljene zunaj mreže TRON (TRC20), ali za pošiljanje kriptovalut, razen TRON (TRX). Vse izgube sredstev zaradi teh napak bodo izključno odgovornost pošiljatelja.",
    _TABLE1: "Zgodovina depozitov",
    _TABLE1_A: "Naročilo",
    _TABLE1_B: "Znesek",
    _TABLE1_C: "Datum",
    _TABLE1_D: "Status",
    _SUCCESS: "Paket je bil uspešno dodan",
    _COPYMESSAGE: "Naslov denarnice je bil uspešno kopiran",
  },
  _PACKAGES: {
    _ATTENTION:
      "Pozor: Imate korporativni račun. Z nakupom paketa boste ponastavili račun in vse njegove koristi.",
    _PACKAGES: "Izberite najboljši načrt za vas",
    _SUBTITLE: "Imate 8 možnosti",
    _BALANCE: "Bilans depozita",
    _PACKAGE: "Paket ",
    _PACKAGE_1: "Zaslužite do nivoja ",
    _PACKAGE_2: "Zaslužite do 3X",
    _PACKAGE_3: "Dnevni ROI",
    _BUTTON1: "Poln",
    _BUTTON2: "Kupite",
    _CALCULATE: "Izračunaj načrt",
    _RECHARGE: "Nimate dovolj denarja, dopolnite svoj denarnico",
    _PACKAGEMESSAGE: "Morate kupiti višji paket",
    _SUCCESS: "Paket je bil uspešno dodan",
    _UPDATE: "Cena za nadgradnjo paketa",
  },
  _NETWORK: {
    _TODAY: "Danes",
    _AVAILABLE: "Na voljo",
    _WITHDRAW: "Skupaj dvignjeno",
    _SEARCHLABEL: "Iskanje po e-pošti",
    _SEARCH: "Iskanje",
    _NETWORK: "Mreža",
    _DIRECTS: "Direktni",
    _SUCCESS: "Uspešno iskanje",
    _ERROR: "Ni del vašega binarnega drevesa.",
    _LEVEL: "Nivo",
    _USERS: "Uporabniki",
    _AMOUNT: "Znesek",
    _USERNAME: "Uporabniško ime",
    _EMAIL: "E-pošta",
    _WHATSAPP: "WhatsApp",
    _PACKAGE: "Paket",
  },
  _PROFILE: {
    _TITLE: "Profil",
    _NAME: "Vaše ime",
    _EMAIL: "Vaša e-pošta",
    _WHATSAPP: "WhatsApp",
    _CURRENTPASSWORD: "Trenutno geslo",
    _PASSWORD: "Novo geslo",
    _CONFIRMPASSWORD: "Potrdite novo geslo",
    _WALLET: "Naslov za dvig",
    _ACTION: "Uredi",
    _TIPS: "Nasveti",
    _TIPS_1: "Ime mora biti čitljivo in ne sme presegati 20 znakov.",
    _TIPS_2:
      "Geslo mora vključevati številke, črke in simbole; bolj ko je zapleteno, bolje je.",
    _TIPS_3:
      "E-pošte ni mogoče spremeniti. Če potrebujete spremembo, pišite podpori.",
    _TIPS_4:
      "Naslov za dvig lahko spremenite le z dvofaktorskim avtentifikatorjem, ki mora biti prej omogočen.",
    _ERROR_PASSWORD: "Napaka pri prejšnjem geslu",
    _SUCCESS: "Profil je bil uspešno posodobljen",
    _MIN: "Minimalni dvig",
    _UPDATE: "Posodobi profil",
  },
  _RECOVERPASSWORD: {
    _RECOVERPASSWORD: "Ste pozabili geslo?",
    _EMAIL: "Vaša e-pošta",
    _BUTTON: "Pošlji e-pošto",
    _MESSAGE: "Vaše geslo je bilo poslano na vašo e-pošto",
    _ERROR_EMAIL: "Ta e-pošta ni registrirana",
  },
  _GLOBALV: {
    _TITLE: "GV",
    _CURRENT: "Trenutni rang",
    _NEXT: "Naslednji rang",
    _SILVER: "Srebro",
    _GOLD: "Zlato",
    _RUBY: "Rubin",
    _EMERALD: "Smaragd",
    _DIAMOND: "Diamant",
    _DIAMONDB: "Diamant Modri",
    _DIAMONDBB: "Diamant Črni",
    _NONE: "Noben",
  },
  _AUTH: {
    _TITLEDEFAULT: "Sprememba naslova za dvig",
    _TITLE: "Korak 1",
    _TITLE2: "Korak 2",
    _TITLE3: "Korak 3",
    _STEP1: "Prenesite in namestite avtentifikator na vaši napravi",
    _STEP2: "Skenirajte kodo za shranitev",
    _FORMEMAIL: "Verifikacija e-pošte",
    _FORMHELPEMAIL: "Vnesite 6-cifreni verifikacijski koda, poslan na",
    _FORMHELPAUTH:
      "Vnesite 6-cifreni koda, generiran s pomočjo aplikacije avtentifikator",
    _FORM1: "Pin z avtentifikatorja",
    _FORM2: "Naslov za dvig",
    _FORMMIN: "Naslov za dvig mora imeti 34 znakov",
    _FORMCODESEND: "Pošlji kodo",
    _VERIFF: "Veriff",
    _ACTION: "Preverite in shranite",
    _ERROR: "Napaka, poskusite znova.",
    _SUCCESS: "Sprememba uspešna",
    _RETRYOTP: "Napaka pri pošiljanju kode, poskusite znova.",
    _SENDOTP: "Koda uspešno poslana",
    _SUCCESSOTP: "OTP uspešno potrjen",
    _SUCCESSAUTH2F: "Auth2fa uspešno potrjen",
    _ERRORCODE: "Neveljavna koda, poskusite znova",
  },
  _COUNTDOWN: {
    _TITLE: "Naslednji prekid:",
    _DAYS: "Dni",
    _HOURS: "Ure",
    _MINUTES: "Minute",
    _SECONDS: "Sekunde",
  },
  _WITHDRAWAL: {
    _TITLE: "Transakcije dvigov",
  },
  _SYSTEM: {
    _SYSTEM: "Sistem",
    _GLOBAL: "Globalno",
    _INVESTMENT: "Dvignite investicijo",
    _INVESTMENTTEXT:
      "Ne pozabite, da boste imeli kazen v višini 25% vaše investicije",
    _ACCELERATE: "Pospeši",
    _REPORT: "Poročilo o investicijah Cryptotradex",
    _PARNERT: "Informacije o sponzorju",
  },
  _WITHDRAWINVESTMENT: {
    _TITLE: "Dvig Naložbe",
    _MESSAGE:
      "Dvig vašega kapitala zdaj vključuje operativno provizijo v višini 25 % in bo obdelan v 24 urah. Če to storite, boste takoj ustavili njegov potencial za rast. Odločite se z dolgoročno vizijo!",
    _BUTTON: "Dvig",
    _BUTTONWAIT: "Dvig v teku",
  },
};
