import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import toast from "react-hot-toast";
import AppContext from "../context/AppContext";

const AdminPackage = () => {
  const navigate = useNavigate();
  const { loading, updateLoading } = useContext(AppContext);
  const { register, handleSubmit, setValue, watch, reset, formState: { errors }, } = useForm();
  const [userSearch, setUserSearch] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    getAllAdmin()
  }, []);

  const onSubmit = async (data) => {
    updateLoading(true);
    const formData = new FormData();
    formData.append("email", data?.email);
    formData.append("packages_type", data?.package);
    fetch(`${process.env.REACT_APP_BACKEND}/admin-packages`, {
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.status) {
          toast.error("Error al asignar paquete, vuelve a intentarlo");
          updateLoading(false);
        } else {
          toast.success("Se agrego el paquete correctamente");
          getAllAdmin();
          reset();
          updateLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  };

  const searchEmail = async (email) => {
    updateLoading(true);
    const formData = new FormData();
    formData.append("email", email);
    fetch(`${process.env.REACT_APP_BACKEND}/exists-email`, {
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.status) {
          toast.error("No existe usuario");
          updateLoading(false);
        } else {
          setUserSearch(response.response)
          if (response?.response?.name) {
            setValue('name', response?.response?.name);
          }
          toast.success("Usuario encontrado sastifactoriamente");
          updateLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  };

  useEffect(() => {
    getAllAdmin();
  }, [page, limit]);



  const getAllAdmin = async () => {
    setUserSearch();
    updateLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND}/admin-packages?page=${page}&limit=${limit}`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.status) {
          if (response.redirect) {
            navigate("/");
          }
          updateLoading(false);
        } else {
          setTransactions(response?.data?.items)
          setTotal(response?.data?.total);
          updateLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  };

  return (
    <div className="container mx-auto h-auto flex flex-col lg:flex-row gap-4 p-4">
      <div className="flex flex-col lg:flex-row gap-4 p-4 h-auto w-full lg:w-1/2 ">
        <div className="relative overflow-x-auto w-full">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 rounded-lg ">
            <thead className="text-md text-white border-b-2">
              <tr>
                <th className="px-6 py-4 ">
                  Usuario
                </th>
                <th className="px-6 py-4 ">
                  Email
                </th>
                <th className="px-6 py-4">
                  Paquete
                </th>
                <th className="px-6 py-4 ">
                  Fecha Activación
                </th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((transaction, index) => (
                <tr className="border-b-2" key={index} >
                  <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap text-white">
                    {transaction?.name}
                  </th>
                  <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap text-white">
                    {transaction?.email}
                  </th>
                  <td className="px-6 py-4 text-white">
                    Paquete N° {transaction?.packages_type}
                  </td>
                  <td className="px-6 py-4 text-white">
                    {transaction.created}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <nav className="flex items-center justify-center flex-column flex-wrap md:flex-row pt-4" aria-label="Table navigation">
            <ul className="flex justify-center items-center h-8">
              {page > 1 && (
                <li>
                  <button className="flex items-center justify-center px-3 h-12 border-2 rounded-lg text-white" onClick={() => setPage((prev) => Math.max(prev - 1, 1))}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                    </svg>
                    PREV
                  </button>
                </li>
              )}
              <li>
                <button className={`flex items-center justify-center px-3 h-12 text-white mx-6`}>
                  {page} / {Math.ceil(total / limit)}
                </button>
              </li>
              {page < Math.ceil(total / limit) && (
                <li>
                  <button className="flex items-center justify-center px-3 h-12 border-2 rounded-lg text-white" onClick={() => setPage((prev) => Math.min(prev + 1, Math.ceil(total / limit)))}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                    </svg>
                    NEXT
                  </button>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </div>
      <div className="w-full lg:w-1/2  text-white p-4 bg-zinc-800 text-white p-6 rounded-lg border-lime-600 hover:border-lime-300 border-2">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <label className="block text-white mb-2" htmlFor="email">
              Correo electrónico
            </label>
            <div className="flex flex-col lg:flex-row gap-4">
              <input
                type="email"
                id="email"
                {...register('email', { required: true })}
                className="bg-gray-900 border-gladiaverse border text-white sm:text-sm rounded-full block w-full p-2.5 pl-4"
              />
              <button
                type="button"
                onClick={() => searchEmail(watch('email'))}
                className="text-md border-2 p-1 cursor-pointer rounded-lg w-full lg:w-32"
              >
                Buscar
              </button>
            </div>
            {errors.email && <p className="text-red-500 text-xs italic">El email es requerido</p>}
          </div>
          <div className="mb-4">
            <label className="block text-white mb-2" htmlFor="name">
              Nombre de Usuario
            </label>
            <input
              type="text"
              id="name"
              {...register('name')}
              disabled
              className="bg-gray-900 border-gladiaverse border text-white sm:text-sm rounded-full block w-full p-2.5 pl-4"
            />
            {errors.name && <p className="text-red-500 text-xs italic">Name is required.</p>}
          </div>
          <div className="mb-4">
            <label className="block text-white mb-2" htmlFor="package">
              Paquete a asignar
            </label>
            <select
              id="package"
              {...register('package', { required: true })}
              className="bg-gray-900  border text-white sm:text-sm rounded-lg block w-full p-2.5 pl-4"
            >
              <option value="" className="bg-gray-900">Seleccione Plan</option>
              <option value="1" className="bg-gray-900">Package 1 - 25 USD</option>
              <option value="2" className="bg-gray-900">Package 2 - 100 USD</option>
              <option value="3" className="bg-gray-900">Package 3 - 250 USD</option>
              <option value="4" className="bg-gray-900">Package 4 - 500 USD</option>
              <option value="5" className="bg-gray-900">Package 5 - 1000 USD</option>
              <option value="6" className="bg-gray-900">Package 6 - 2500 USD</option>
              <option value="7" className="bg-gray-900">Package 7 - 5000 USD</option>
              <option value="8" className="bg-gray-900">Package 8 - 10000 USD</option>
              <option value="9" className="bg-gray-900">Package 9 - 20000 USD</option>
              <option value="10" className="bg-gray-900">Package 10 - 50000 USD</option>
            </select>
            {errors.package && <p className="text-red-500 text-xs italic">El paquete debe ser seleccionado</p>}
          </div>
          {userSearch && userSearch?.is_corporate === "0" && userSearch?.active === "1" && (
            <p className="text-xs font-medium me-2 p-2 rounded bg-red-900 text-red-300 my-2">Este usuario ya tiene un paquete comprado, si le anexas un paquete corporativo perdera todos sus beneficios</p>
          )}
          <div className="mb-4">
            <button
              type="submit"
              className="text-md border-2 p-1 cursor-pointer rounded-lg w-full"
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminPackage;
