import React from 'react';

const CustomButton = ({ disabled, text, onClick, isSubmit, fontSize, align }) => {
    return (
        <div className=''>
            <div className={`flex ${align}`}>
                <button
                    type={isSubmit ? 'submit' : 'button'}
                    className={`w-full min-w-28 custom-button rounded-lg py-4 font2 ${fontSize} text-gray ${disabled ? 'opacity-10 cursor-not-allowed' : ''}`}
                    onClick={onClick}
                    disabled={disabled}
                >
                    <h1>{text}</h1>
                </button>
            </div>
        </div>
    );
};

export default CustomButton;
