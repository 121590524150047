import React, { useState, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import toast from "react-hot-toast";
import CustomButton from '../components/CustomButton';
import DogRegister from "../assets/svg/DogRegister.svg"
import BackgroundRegister from "../assets/svg/Register.svg"
import GladiverseLogo from "../assets/svg/logo.svg"
import AppContext from "../context/AppContext";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';


export default function Signup() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [inviteCode, setInviteCode] = useState('');
    const [parnertEmail, setParnertEmail] = useState('');
    const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm();
    const [showPassword, setShowPassword] = useState(false);
    const { loading, updateLoading } = useContext(AppContext);
    const [phone, setPhone] = useState('');

    useEffect(() => {
        // Obtener el valor del parámetro 'invite' de la URL
        const values = queryString.parse(window.location.search);
        if (values.invite) {
            setValue('referralCode', values.invite);
            searchParnerts(values.invite);
        }
    }, []);

    const onSubmit = data => {
        updateLoading(true);
        const formData = new FormData();

        formData.append("name", data?.name);
        formData.append("email", data?.email);

        formData.append("whatsapp", phone);
        formData.append("telegram", data?.telegram);

        formData.append("password", data?.password);
        formData.append("code", data?.referralCode);

        fetch(`${process.env.REACT_APP_BACKEND}/register`, {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(response => {
                if (!response.status) {
                    if (response?.code === true) {
                        toast.error(t("_REGISTER._ERROR_REFERAL"));
                    }
                    if (response?.email === true) {
                        toast.error(t("_REGISTER._ERROR_EMAIL"))
                    }
                    updateLoading(false);
                } else {
                    localStorage.setItem("token", response?.token);
                    localStorage.setItem("address", response?.user?.address);
                    localStorage.setItem("id", response?.user?.id);
                    localStorage.setItem("user", JSON.stringify(response?.user));
                    toast.success(t("_REGISTER._SUCCESS"));
                    navigate("/");
                }
            })
            .catch((error) => {
                updateLoading(false);
                console.error('Error:', error);
            });
    };



    const searchParnerts = (code) => {
        const formData = new FormData();
        formData.append("code", code);
        fetch(`${process.env.REACT_APP_BACKEND}/exists-code`, {
            method: "POST",
            body: formData
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(response);
                setParnertEmail(response.response)
            })
            .catch((error) => {
                console.log(error);
                updateLoading(false);
            });
    }
    return (
        <div className="flex mx-auto md:h-screen pt:mt-0 bg-cover bg-no-repeat" style={{ backgroundImage: `url(${BackgroundRegister})` }}>
            <div className='lg:w-3/5 h-full flex justify-center items-center'>
                <img src={GladiverseLogo} className="w-3/5 mb-4 mx-auto hidden lg:block" alt={"Cryptotradex"} />
            </div>
            <div className="custom-button-shadow  custom-button-shadow-two  flex flex-col justify-center items-center w-full lg:w-9/12 p-8 h-full lg:h-screen lg:h-auto lg:p-6 rounded-lg shadow z-50 bg-cover bg-black bg-no-repeat  bg-center " style={{ backgroundImage: `url(${DogRegister})` }}>
                <h2 className="text-2xl text-white">
                    {t("_REGISTER._REGISTER")}
                </h2>
                <form className="mt-8 lg:space-y-0 z-40 w-full flex flex-col lg:grid lg:grid-cols-2 gap-6 px-6" onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <label htmlFor="name" className="block mb-2 font-medium text-gray-900 text-white">{t("_REGISTER._NAME")}</label>
                        <input
                            id="name"
                            type="text"
                            {...register('name', {
                                required: 'Username is required',
                                maxLength: { value: 100, message: 'Username cannot exceed 100 characters' }
                            })}
                            className="text-white sm:text-sm"
                            autoComplete={false}
                        />
                        {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name.message}</p>}
                    </div>

                    <div>
                        <label htmlFor="email" className="block mb-2 font-medium text-gray-900 text-white">{t("_REGISTER._EMAIL")}</label>
                        <input type="email" name="email" id="email" className="text-white sm:text-sm" autoComplete={false} placeholder="name@company.com" {...register('email', { required: 'Email is required' })} />
                        {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email.message}</p>}
                    </div>
                    <div>
                        <label htmlFor="whatsapp" className="block mb-2 font-medium text-gray-900 text-white">{t("_REGISTER._WHATSAPP")}</label>
                        <PhoneInput
                            className="text-white sm:text-sm inputphone"
                            defaultCountry="uk"
                            value={phone}
                            onChange={(phone) => setPhone(phone)}
                        />
                    </div>
                    <div>
                        <label htmlFor="telegram" className="block mb-2 font-medium text-gray-900 text-white">{t("_REGISTER._TELEGRAM")}</label>
                        <input
                            id="telegram"
                            type="text"
                            autoComplete={false}
                            {...register('telegram')}
                            className="text-white sm:text-sm"
                        />
                    </div>
                    <div>
                        <label htmlFor="password" className="block mb-2 font-medium text-gray-900 text-white" autoComplete={false}>{t("_REGISTER._PASSWORD")}</label>
                        <div className="relative">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                id="password"
                                placeholder="••••••••"
                                className="text-white sm:text-sm"
                                {...register('password', {
                                    required: 'Password is required',
                                    minLength: { value: 6, message: 'Password must have at least 6 characters' },
                                    pattern: {
                                        value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*]{6,}$/,
                                        message: 'The password must contain at least one letter and one number min 6 characters',
                                    },
                                })}
                            />
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                                {showPassword ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="white" className="size-6" onClick={() => setShowPassword(!showPassword)}>
                                        <path stroke-linecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                        <path stroke-linecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                    </svg>
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" className="size-6" onClick={() => setShowPassword(!showPassword)}>
                                        <path stroke-linecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                                    </svg>
                                )}
                            </div>
                        </div>
                        {errors.password && <p className="text-red-500 text-xs mt-1">{errors.password.message}</p>}
                    </div>
                    <div>
                        <label htmlFor="confirmPassword" className="block mb-2 font-medium text-gray-900 text-white">{t("_REGISTER._CONFIRMPASSWORD")}</label>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            name="confirmPassword"
                            id="confirmPassword"
                            placeholder="••••••••"
                            className="text-white sm:text-sm"
                            autoComplete={false}
                            {...register('confirmPassword', {
                                validate: value => value === watch('password') || 'Passwords do not match'
                            })}
                        />
                        {errors.confirmPassword && <p className="text-red-500 text-xs mt-1">{errors.confirmPassword.message}</p>}
                    </div>
                    {parnertEmail?.email && (
                        <div className='col-span-2 '>
                            <label htmlFor="parnert" className="block mb-2 font-medium text-gray-900 text-white">{t("_SYSTEM._PARNERT")}</label>
                            <input
                                type="text"
                                value={parnertEmail?.email}
                                disabled
                                className="text-white sm:text-sm"
                            />
                        </div>
                    )}
                    {/* 
                    <div className="col-span-2  text-sm font-medium text-gray-400 text-center">
                        <Link to="/policy-and-privacy" target={"_blank"} className="hover:underline text-primary-500">Policy and privacy </Link>
                    </div>
                    */}
                    <div className='col-span-2 flex justify-center flex-col'>
                        <CustomButton text={t("_REGISTER._REGISTER")} isSubmit={true} disabled={!watch("referralCode") || !watch("name") || !watch("email") || !watch("password") || !watch("confirmPassword") || !phone || loading} fontSize={"text-1xl w-3/6"} align="justify-center" />
                    </div>
                    <div className="col-span-2 flex justify-center mx-auto">
                        <Link to="/signin" className="mt-2 hover:underline text-center text-white underline font2">{t("_REGISTER._HAVEACCOUNT")}</Link>
                    </div>
                </form>
            </div>
        </div>
    );
}