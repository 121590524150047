import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import AppContext from "../context/AppContext";
import { useFloating, shift, offset, arrow } from '@floating-ui/react'; // Importa los hooks de @floating-ui/react
import Cookies from "js-cookie"; // Importa la librería de cookies
import Package25 from "../assets/svg/25.svg";
import Package100 from "../assets/svg/100.svg";
import Package250 from "../assets/svg/250.svg";
import Package500 from "../assets/svg/500.svg";
import Package1000 from "../assets/svg/1000.svg";
import Package2500 from "../assets/svg/2500.svg";
import Package5000 from "../assets/svg/5000.svg";
import Package10000 from "../assets/svg/10000.svg";
import Package20000 from "../assets/svg/20000.svg";
import Package50000 from "../assets/svg/50000.svg";

export default function Packages() {
  const { t } = useTranslation();
  const [balance, setBalance] = useState([]);
  const { loading, updateLoading } = useContext(AppContext);
  const [userData, setUserData] = useState([]);
  const [packagesUser, setPackageUser] = useState(0);
  const [calculatedCosts, setCalculatedCosts] = useState({}); // Estado para almacenar los valores calculados por índice
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [upgradeShow, setupgradeShow] = useState(false);
  const [arrowRef, setArrowRef] = useState(null); // Referencia del arrow para popper


  useEffect(() => {
    getUsersData();
    const savedCosts = {};
    items.forEach((item, index) => {
      const savedCost = Cookies.get(`calculatedCost_${index}`); // Obtén las cookies para cada índice
      if (savedCost) {
        savedCosts[index] = savedCost;
      }
    });
    setCalculatedCosts(savedCosts); // Guarda los costos calculados en el estado
  }, []);

  const items = [
    { cost: 25, title: 'Bone', image: Package25, level: 1 },
    { cost: 100, title: 'Baby Doge', image: Package100, level: 2 },
    { cost: 250, title: 'Popcat', image: Package250, level: 3 },
    { cost: 500, title: 'Brett', image: Package500, level: 4 },
    { cost: 1000, title: 'Floki', image: Package1000, level: 5 },
    { cost: 2500, title: 'Bonk', image: Package2500, level: 7 },
    { cost: 5000, title: 'Dogwifhat', image: Package5000, level: 8 },
    { cost: 10000, title: 'Pepe', image: Package10000, level: 9 },
    { cost: 20000, title: 'Shiba', image: Package20000, level: 10 },
    { cost: 50000, title: 'Doge', image: Package50000, level: 10 },
  ];

  const handlePurchase = (position, calculatedCost) => {
    if (calculatedCost <= 0) {
      toast.error(t("_PACKAGES._PACKAGEMESSAGE"));
      return;
    }
    updateLoading(true);
    const formData = new FormData();
    formData.append("packages_type", position);
    formData.append("amount", calculatedCost); // Agrega el valor calculado
    fetch(`${process.env.REACT_APP_BACKEND}/packages`, {
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.status) {
          if (response.balance === false) {
            toast.error(t("_PACKAGES._RECHARGE"));
          }
          if (response.package === false) {
            toast.error(t("_PACKAGES._PACKAGEMESSAGE"));
          }
          updateLoading(false);
        } else {
          toast.success(t("_PACKAGES._SUCCESS"));
          getUsersData();
          updateLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  };

  const getUsersData = () => {
    updateLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND}/users`, {
      method: "GET",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        setUserData(response?.data);
        let userPackage = response?.data?.package;
        let userCorporative = response?.data?.is_corporate;
        setPackageUser(userCorporative === "1" ? 0 : userPackage);
        updateLoading(false);
      })
      .catch((error) => {
        console.log(error);
        updateLoading(false);
      });
  };

  const calculate = (cost, index) => {
    if (parseInt(userData?.package_price) !== 0 && userData?.is_corporate !== "1") {
      //Upgrade actualizado
      if (parseInt(userData?.package) === parseInt(index + 1)) {
        console.log('EQUAL')
      } else {
        setupgradeShow(true);
        cost = parseInt(cost) - parseInt(userData?.package_price);
        if (cost < 0) {
          cost = parseInt(cost);
        }
      }
    }
    const calculatedValue = oraculo(cost);
    Cookies.set(`calculatedCost_${index}`, calculatedValue, { expires: 1 / 24 }); // Crea una cookie única para cada índice, que dura 1 hora
    setCalculatedCosts((prevCosts) => ({
      ...prevCosts,
      [index]: calculatedValue,
    })); // Actualiza el estado con el valor calculado
  };

  const oraculo = (props) => {
    if (window.localStorage.getItem("tron")) {
      let value = props / window.localStorage.getItem("tron");
      let formatted = Number.parseFloat(value).toFixed(2);
      return formatted;
    }
  };

  return (
    <div className="mx-auto">
      <div className="p-6 rounded-lg ">
        {userData?.is_corporate === "1" && (
          <div className="w-full my-2 mx-auto">
            <span className="bg-red-100 text-red-800 text-xs font-medium me-2 p-2 my-2 rounded dark:bg-red-900 dark:text-red-300">
              {t("_PACKAGES._ATTENTION")}
            </span>
          </div>
        )}
        <div className="flex flex-col lg:flex-row justify-between mb-2">
          <div>
            <h3 className="text-lg text-white">{t("_PACKAGES._PACKAGES")}</h3>
          </div>
          <div>
            <h3 className="text-lg text-white">{t("_PACKAGES._BALANCE")}</h3>
            <p className="text-lg text-white">{userData?.balance_deposit} TRX</p>
          </div>
        </div>
        <div className="my-4 w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
          {items.map((item, index) => (
            <div key={index} className="w-full ">
              <div className="rounded-lg text-white flex flex-col my-12 relative">
                <div className="h-auto flex flex-col z-10 w-full border-lime-600 hover:border-lime-300 border-2 bg-zinc-800 rounded-lg shadow-md py-4">
                  <div className="rounded-lg p-4 relative">
                    <img
                      src={item?.image}
                      alt={item?.title}
                      className="absolute -top-20 left-1/2 transform -translate-x-1/2 w-32"
                    />
                    <h2 className="mb-4 text-2xl text-lime-300 text-center my-10">
                      {item?.title}
                    </h2>
                    <div className="flex flex-col my-14 font2 gap-2 text-sm">
                      <p>
                        {t("_PACKAGES._PACKAGE_1")}{" "}{item.level}
                      </p>
                      <p>{t("_PACKAGES._PACKAGE_2")}</p>
                    </div>
                    <div className="flex flex-col items-center justify-center my-2 space-y-2">
                      <p className="text-white">{item.cost} USD</p>
                    </div>
                  </div>

                  <div className="mx-2 z-40">
                    {hoveredIndex === index && (
                      <div
                        className="absolute bottom-16 left-1/2 transform -translate-x-1/2 z-10 inline-block w-60 text-sm text-gray-500 transition-opacity duration-300 rounded-lg shadow-sm"
                      >
                        <div className="px-3 py-2 bg-zinc-700 border-2 rounded-t-lg border-lime-600 ">
                          <h3 className="text-center text-white">
                            {t("_PACKAGES._UPDATE")}
                          </h3>
                        </div>
                        <div className="data-popper-arrow"></div>
                      </div>
                    )}
                    {parseInt(packagesUser) <= parseInt(index + 1) ? <>
                      {calculatedCosts[index] ? (
                        // Si la cookie existe para este índice, muestra el botón de handlePurchase
                        <button
                          onClick={() => handlePurchase(index + 1, calculatedCosts[index])}
                          onMouseEnter={() => {
                            if (upgradeShow) {
                              setHoveredIndex(index);
                            }
                          }}
                          // Mostrar popover al hacer hover
                          onMouseLeave={() => setHoveredIndex(null)}
                          // Ocultar popover al salir del hover
                          className="text-md w-full border-2 p-1 cursor-pointer rounded-lg"
                        >
                          {t("_PACKAGES._BUTTON2")} ({calculatedCosts[index]} TRX)
                        </button>
                      ) : (
                        // Si no existe cookie, muestra el botón de calcular
                        <button
                          onClick={() => calculate(item.cost, index)}
                          className="text-md w-full border-2 p-1 cursor-pointer rounded-lg"
                        >
                          {t("_PACKAGES._CALCULATE")}
                        </button>
                      )}
                    </>
                      :
                      <>
                        <button className="text-md w-full border-2 p-1 cursor-pointer rounded-lg opacity-20" disabled> {t("_PACKAGES._BUTTON1")}</button>
                      </>}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
